import { Box, Divider, Menu, MenuItem, MenuProps } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Nullable } from '@src/common-utils/Models';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/login';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import { UserInfo, isAdminRole, isSystemAdminRole } from '@src/common-utils/AuthModels';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export type HeaderMenuItem =
  | 'my-cart'
  | 'login'
  | 'logout'
  | 'reset-password'
  | 'profile'
  | 'user-management'
  | 'clinic-management'
  | 'my-clinic';

type HeaderMenuProps = {
  anchorElement: Nullable<HTMLElement>;
  onClose: () => void;
  onMenuItemSelect: (mentItem: HeaderMenuItem) => void;
  userInfo?: Nullable<UserInfo>;
};

export const HeaderMenu = ({ anchorElement, onClose, onMenuItemSelect, userInfo }: HeaderMenuProps) => {
  const isSignedIn = !!userInfo;
  const isAdmin = isSignedIn && userInfo.groups?.find((group) => isAdminRole(group));
  const isSystemAdmin = isSignedIn && userInfo.groups?.find((group) => isSystemAdminRole(group));

  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onClose}
    >
      {isSignedIn && (
        <Box component="div">
          <MenuItem onClick={() => onMenuItemSelect('my-cart')} disableRipple>
            <ShoppingCartIcon />
            My Cart
          </MenuItem>
          <MenuItem onClick={() => onMenuItemSelect('profile')} disableRipple>
            <AccountCircleIcon />
            Profile
          </MenuItem>
          <MenuItem onClick={() => onMenuItemSelect('reset-password')} disableRipple>
            <VpnKeyIcon />
            Reset Password
          </MenuItem>{' '}
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={() => onMenuItemSelect('my-clinic')} disableRipple>
            <LocalPharmacyIcon />
            My Clinic
          </MenuItem>
          {isAdmin && (
            <>
              {isSystemAdmin && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => onMenuItemSelect('user-management')} disableRipple>
                    <PeopleIcon />
                    Customer Manage
                  </MenuItem>
                </>
              )}
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={() => onMenuItemSelect('clinic-management')} disableRipple>
                <LocalHospitalIcon />
                Clinic Manage
              </MenuItem>
            </>
          )}
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={() => onMenuItemSelect('logout')} disableRipple>
            <LogoutIcon />
            Log Out
          </MenuItem>
        </Box>
      )}
      {!isSignedIn && (
        <MenuItem onClick={() => onMenuItemSelect('login')} disableRipple>
          <LoginIcon />
          SignIn | SignUp
        </MenuItem>
      )}
    </StyledMenu>
  );
};

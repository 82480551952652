import { CSSProperties } from 'react';
import clsx from 'clsx';
import '@src/icon/Icons.scss';
import { ReactComponent as ToothIcon } from '@src/icon/tooth.svg';
import { ReactComponent as DentalChairIcon } from '@src/icon/dental-chair.svg';
import { ReactComponent as GuruHealthIcon } from '@src/icon/guru-health.svg';

const IconMap = {
  tooth: ToothIcon,
  dentalChair: DentalChairIcon,
  guruHealth: GuruHealthIcon,
};
export type IconName = keyof typeof IconMap;

export const IconSize = {
  xs: 'Icon--xs',
  sm: 'Icon--sm',
  md: 'Icon--md',
  lg: 'Icon--lg',
  xl: 'Icon--xl',
  ns: 'Icon--noSize',
};
export type IconSize = keyof typeof IconSize;

export const AppIcon = ({
  name,
  size = 'md',
  alt,
  className,
  color,
}: {
  name: IconName;
  size?: IconSize;
  alt?: string;
  className?: string;
  color?: string;
}) => {
  const SelectedIcon = IconMap[name];
  if (!SelectedIcon) return null;

  const customStyle: CSSProperties | undefined = color ? { fill: color } : undefined;

  return (
    <span className={clsx('Icon', IconSize[size], className)} data-icon={name} aria-label={alt} style={customStyle}>
      <SelectedIcon />
    </span>
  );
};

import { Box, Button, Container, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { ROUTES } from '@src/layout/RoutePage';
import CheckIcon from '@mui/icons-material/Check';
import { LinkLoadingButton } from '@src/components/common/LinkLoadingButton';
import { useRef, useState } from 'react';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { Nullable } from '@src/common-utils/Models';

export const SignupSuccessPage = () => {
  const { isLoading, resendConfirmationCode } = useUserContext();

  const location = useLocation();
  const initialLocationState = useRef(location.state);
  const { title, email } = initialLocationState.current;

  const [resendVerificationMessage, setResendVerificationMessage] = useState<Nullable<string>>(null);
  const [resendVerificationError, setResendVerificationError] = useState<Nullable<Error>>(null);

  const resendCode = () => {
    resendConfirmationCode({
      email,
      onSuccess: () => {
        setResendVerificationError(null);
        setResendVerificationMessage(`Sign up verification was sent to ${email}`);
      },
      onError: (error: Error) => {
        setResendVerificationMessage(null);
        setResendVerificationError(error);
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ color: 'primary', mb: '8px' }} align="center">
          {title}
        </Typography>
        <Typography component="h4" variant="body2">
          <strong>Congratulations!</strong> Your sign-up was successful. We have sent a verification email to your
          registered email {email}. Please check your inbox (and spam folder, just in case) and click on the
          verification link to complete your registration. Once you've verified your email, you can{' '}
          <MuiLink component={RouterLink} to={ROUTES.signin} color="primary">
            Log in
          </MuiLink>{' '}
          to your account. Thank you for joining us!
        </Typography>
        <Typography component="span" variant="body2" sx={{ mt: '8px' }}>
          <LinkLoadingButton
            label="Resend confirmation code"
            isLoading={isLoading}
            sx={{ ml: '4px' }}
            onClick={resendCode}
          />
        </Typography>
        {resendVerificationMessage && (
          <Typography component="span" variant="body2" sx={{ color: 'blue', mt: '4px' }}>
            {resendVerificationMessage}
          </Typography>
        )}
        {resendVerificationError && (
          <Typography component="span" variant="body2" sx={{ color: 'red', mt: '4px' }}>
            {`Error: ${resendVerificationError.message}`}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export const PasswordResetSuccessPage = () => {
  const navigate = useNavigate();
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <Typography
          component="div"
          variant="h5"
          sx={{ color: 'primary', mb: '8px', display: 'flex', alignItems: 'center', gap: '8px' }}
          align="center"
        >
          <CheckIcon />
          Password was reset
        </Typography>
        <Typography component="h4" variant="body2">
          Your password has beeb reset successfully!
        </Typography>
        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => navigate(ROUTES.signin)}>
          Retrun to Sign In
        </Button>
      </Box>
    </Container>
  );
};

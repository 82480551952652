import {
  ClinicDTO,
  ClinicMemberDTO,
  ClinicMemberIdentifier,
  CreateClinicDTO,
  GetClinicMembersResponse,
  GetClinicsResponse,
  GetMemberClinicsResponse,
  MemberClinicDTO,
  PatchMemberClinicDTO,
} from '@src/common-utils/DataModels';
import { QueryResult } from '@src/common-utils/Models';
import axiosInstance, { clinicsEndpoint, membersEndpoint } from '@src/libs/axios-util';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const clinicsQueryKey = ['clinics'];

type ClinicQueryProps = {
  onError?: (error: Error) => void;
};

export const useClinicQuery = ({ onError }: ClinicQueryProps = {}): QueryResult<ClinicDTO[]> => {
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery<ClinicDTO[], Error>(
    clinicsQueryKey,
    async () => {
      const res = await axiosInstance.get<GetClinicsResponse>(clinicsEndpoint);
      const clinics = res.data.clinics;
      clinics.sort((c1, c2) => c1.name.toLowerCase().localeCompare(c2.name.toLowerCase()));
      return clinics;
    },
    {
      onError: (e) => {
        if (onError) onError(e);
      },
    },
  );

  return {
    data: data || [],
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
  };
};

const doCreateClinic = async (createClinicData: CreateClinicDTO): Promise<ClinicDTO> => {
  const resp = await axiosInstance({ url: clinicsEndpoint, method: 'POST', data: createClinicData });
  return resp.data;
};

const doUpdateClinic = async ({ clinicId, createClinicData }: UpdateClinicQueryInput): Promise<ClinicDTO> => {
  const resp = await axiosInstance({
    url: `${clinicsEndpoint}/${encodeURIComponent(clinicId)}`,
    method: 'PATCH',
    data: createClinicData,
  });
  return resp.data;
};

const doDeleteClinic = async (clinicId: string): Promise<void> => {
  await axiosInstance({
    url: `${clinicsEndpoint}/${encodeURIComponent(clinicId)}`,
    method: 'DELETE',
  });
};

const doAddClinicMember = async ({ clinicId, memberEmail }: AddClinicMemberQueryInput): Promise<ClinicMemberDTO> => {
  const resp = await axiosInstance({
    url: `${clinicsEndpoint}/${encodeURIComponent(clinicId)}/members`,
    method: 'POST',
    data: { memberEmail },
  });
  return resp.data;
};

type UpdateClinicQueryInput = {
  clinicId: string;
  createClinicData: CreateClinicDTO;
};

export const useCreateClinicQuery = ({
  onSuccess,
  onError,
}: {
  onSuccess: (res: ClinicDTO) => Promise<void>;
  onError?: (error: Error) => void;
}): {
  createClinic: UseMutateFunction<ClinicDTO, Error, CreateClinicDTO, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate: createClinic, isLoading } = useMutation(doCreateClinic, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(clinicsQueryKey);
      onSuccess(data);
    },
    onError,
  });

  return { createClinic, isLoading };
};

export const useUpdateClinicQuery = ({
  onSuccess,
  onError,
}: {
  onSuccess: (res: ClinicDTO) => Promise<void>;
  onError?: (error: Error) => void;
}): {
  updateClinic: UseMutateFunction<ClinicDTO, Error, UpdateClinicQueryInput, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate: updateClinic, isLoading } = useMutation(doUpdateClinic, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(clinicsQueryKey);
      onSuccess(data);
    },
    onError,
  });

  return { updateClinic, isLoading };
};

export const useDeleteClinicQuery = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => Promise<void>;
  onError?: (error: Error) => void;
}): {
  deleteClinic: UseMutateFunction<void, Error, string, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate: deleteClinic, isLoading } = useMutation(doDeleteClinic, {
    onSuccess: () => {
      queryClient.invalidateQueries(clinicsQueryKey);
      onSuccess();
    },
    onError,
  });

  return { deleteClinic, isLoading };
};

export type AddClinicMemberQueryInput = {
  clinicId: string;
  memberEmail: string;
};

export const useAddClinicMemberQuery = ({
  onSuccess,
  onError,
}: {
  onSuccess: (res: ClinicMemberDTO) => Promise<void>;
  onError?: (error: Error) => void;
}): {
  addClinicMember: UseMutateFunction<ClinicMemberDTO, Error, AddClinicMemberQueryInput, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate: addClinicMember, isLoading } = useMutation(doAddClinicMember, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          Array.isArray(query.queryKey) &&
          ((query.queryKey.length === 3 &&
            query.queryKey[0] === clinicsQueryKey[0] &&
            query.queryKey[2] === 'members') ||
            (query.queryKey.length === 2 &&
              query.queryKey[0] === 'members' &&
              query.queryKey[1] === clinicsQueryKey[0])),
      });
      onSuccess(data);
    },
    onError,
  });

  return { addClinicMember, isLoading };
};

type ClinicMemberQueryProps = { clinicId: string } & ClinicQueryProps;

export const useClinicMemberQuery = ({ onError, clinicId }: ClinicMemberQueryProps): QueryResult<ClinicMemberDTO[]> => {
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery<ClinicMemberDTO[], Error>(
    [...clinicsQueryKey, clinicId, 'members'],
    async () => {
      const res = await axiosInstance.get<GetClinicMembersResponse>(
        `${clinicsEndpoint}/${encodeURIComponent(clinicId)}/members`,
      );
      return res.data.clinicMembers;
    },
    {
      onError: (e) => {
        if (onError) onError(e);
      },
    },
  );

  return {
    data: data || [],
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
  };
};

const doDeleteClinicMember = async ({ clinicId, memberId }: ClinicMemberIdentifier): Promise<void> => {
  await axiosInstance({
    url: `${clinicsEndpoint}/${encodeURIComponent(clinicId)}/members/${encodeURIComponent(memberId)}`,
    method: 'DELETE',
  });
};

export const useDeleteClinicMemberQuery = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => Promise<void>;
  onError?: (error: Error) => void;
}): {
  deleteClinicMember: UseMutateFunction<void, Error, ClinicMemberIdentifier, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate: deleteClinicMember, isLoading } = useMutation(doDeleteClinicMember, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          Array.isArray(query.queryKey) &&
          ((query.queryKey.length === 3 &&
            query.queryKey[0] === clinicsQueryKey[0] &&
            query.queryKey[2] === 'members') ||
            (query.queryKey.length === 2 &&
              query.queryKey[0] === 'members' &&
              query.queryKey[1] === clinicsQueryKey[0])),
      });
      onSuccess();
    },
    onError,
  });

  return { deleteClinicMember, isLoading };
};

type MemberClinicQueryProps = {
  onError?: (error: Error) => void;
  defaultClinic?: boolean;
};

const memberClinicKey = ['members', ...clinicsQueryKey];
export const useMemberClinicQuery = ({ onError, defaultClinic = false }: MemberClinicQueryProps = {}): QueryResult<
  MemberClinicDTO[]
> => {
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery<MemberClinicDTO[], Error>(
    [...memberClinicKey, String(defaultClinic)],
    async () => {
      const res = await axiosInstance.get<GetMemberClinicsResponse>(`${membersEndpoint}/clinics`, {
        params: {
          defaultClinic,
        },
      });
      return res.data.memberClinics;
    },
    {
      onError: (e) => {
        if (onError) onError(e);
      },
    },
  );

  return {
    data: data || [],
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
  };
};

const doUpdateMemberClinic = async (data: PatchMemberClinicDTO): Promise<PatchMemberClinicDTO> => {
  await axiosInstance({
    url: `${membersEndpoint}/clinics/${encodeURIComponent(data.clinicId)}`,
    method: 'PATCH',
    data,
  });
  return data;
};

export const useUpdateMemberClinicQuery = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: PatchMemberClinicDTO) => Promise<void>;
  onError?: (error: Error) => void;
}): {
  updateMemberClinic: UseMutateFunction<PatchMemberClinicDTO, Error, PatchMemberClinicDTO, unknown>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate: updateMemberClinic, isLoading } = useMutation(doUpdateMemberClinic, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          Array.isArray(query.queryKey) && query.queryKey[0] === 'members' && query.queryKey[1] === clinicsQueryKey[0],
      });

      if (onSuccess) onSuccess(data);
    },
    onError,
  });

  return { updateMemberClinic, isLoading };
};

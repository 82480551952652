import axiosInstance from '@src/libs/axios-util';

export const verifyReCaptchaToken = async (token: string) => {
  await axiosInstance.post(
    '/v1/recaptcha/siteverify',
    { token },
    // {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // },
  );
};

import { Box, Card, CardContent, CardHeader, CircularProgress, Container, IconButton, Typography } from '@mui/material';
import { ClinicMemberStatus, MemberClinicDTO } from '@src/common-utils/DataModels';
import { Nullable } from '@src/common-utils/Models';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';

type MyClinicMobileProps = {
  data: MemberClinicDTO[];
  error?: Nullable<Error>;
  handleSetDefaultClinic: (dto: MemberClinicDTO) => void;
  updateAction?: Nullable<string>;
  currentUpdatedMember?: Nullable<MemberClinicDTO>;
};

const contentWidth = 360;

export const MyClinicMobile = ({
  data,
  handleSetDefaultClinic,
  updateAction,
  currentUpdatedMember,
}: MyClinicMobileProps) => {
  const canBeSetAsDefault = (member: MemberClinicDTO) => member.status === ClinicMemberStatus.ACCEPTED;

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <LocalPharmacyIcon color="info" sx={{ mr: 1 }} />
        <Box>
          <Typography variant="h6">My Clinic</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
        {data?.map((member) => (
          <Card sx={{ width: contentWidth }} key={`clinic-${member.clinic.id}`}>
            <CardHeader
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {/* <ClinicMenuRenderer
                    data={member}
                    context={{
                      handleSelectClinicMenu,
                    }}
                  /> */}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Typography variant="h6">{member.clinic.name}</Typography>
                    {!updateAction && (
                      <IconButton
                        sx={{ ml: 0 }}
                        onClick={() => {
                          handleSetDefaultClinic(member);
                        }}
                      >
                        {member.isDefault && <StarIcon fontSize="medium" color="success" />}
                        {!member.isDefault && (
                          <StarBorderIcon
                            fontSize="medium"
                            color={canBeSetAsDefault(member) ? 'primary' : 'disabled'}
                          />
                        )}
                      </IconButton>
                    )}
                    {updateAction === 'ChangeDefaultClinic' && member.clinic.id === currentUpdatedMember?.clinic.id && (
                      <CircularProgress size={16} sx={{ ml: 1 }} />
                    )}
                  </Box>
                  <IconButton>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {/* <CircularProgress size={16} /> */}
                    </Box>
                  </IconButton>
                </Box>
              }
              subheader={member.clinic.phoneNumber}
            />
            <CardContent sx={{ flexGrow: 1, maxHeight: 120, overflow: 'auto', mt: 0, pt: 0 }}>
              <Typography variant="body1">{member.clinic.address}</Typography>
            </CardContent>
            {/* <Divider sx={{ mr: 1, ml: 1 }} /> */}
            {/* <CardActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton size="large">
              <EditNoteIcon fontSize="medium" color="primary" onClick={() => onEditClinic(clinic)} />
            </IconButton>
            <IconButton size="medium" onClick={() => onDeleteClinic(clinic.id)}>
              <DeleteForeverIcon fontSize="medium" color="warning" />
            </IconButton>
          </CardActions> */}
          </Card>
        ))}
      </Box>
    </Container>
  );
};

import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@src/layout/RoutePage';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { useState } from 'react';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Nullable, phoneRegex } from '@src/common-utils/Models';
import ReCAPTCHA from 'react-google-recaptcha';
import envConfig from '@src/env';
import { verifyReCaptchaToken } from '@src/queries/VerifyRecaptchaQuery';

const schema = yup.object({
  email: yup.string().email('Invalid email format').required('Required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address required'),
  phoneNumber: yup
    .string()
    .required('Phone number required')
    .test('phone-validate', 'Invalid phone number', (value: string | undefined) => {
      if (!value || !value.trim()) return false;
      return phoneRegex.test(value.trim());
    }),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .max(32),
  confirmPassword: yup
    .string()
    .required('Confirm passowrd is required')
    .oneOf([yup.ref('password'), ''], `Passwords don't match`),
});

type SignUpFormData = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  password: string;
  confirmPassword: string;
};

export default function SignUp() {
  const navigate = useNavigate();

  const { signUp, isLoading } = useUserContext();

  const [error, setError] = useState<Nullable<Error>>(null);

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
  };

  const openSignIn = () => {
    navigate(ROUTES.signin);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignUpFormData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: SignUpFormData) => {
    if (!recaptchaValue) {
      setError(new Error('Please complete the reCAPTCHA check to provide that you are not a robot'));
      return;
    }

    try {
      await verifyReCaptchaToken(recaptchaValue);
      setError(null);
    } catch (e) {
      setError(new Error('reCAPTCHA verify failed!'));
      return;
    }

    await signUp({
      signupData: { ...data, phoneNumber: data.phoneNumber.trim() },
      onSuccess: () => {
        navigate(ROUTES.signupSuccess, {
          state: {
            title: 'Verfication Email Sent',
            email: data.email,
          },
        });
      },
      onError: (error: Error) => setError(error),
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message || ''}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}> */}
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoComplete="family-name"
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="phoneNumber"
                    label="Phone"
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="address"
                    label="Address"
                    multiline
                    rows={2}
                    maxRows={4}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((preShow) => !preShow)}
                            onMouseDown={(e) => e.preventDefault()}
                            size="small"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfirmPassword((preShow) => !preShow)}
                            onMouseDown={(e) => e.preventDefault()}
                            size="small"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
            {error && (
              <Grid item xs={12}>
                <Typography component="h4" variant="body2" color="error">
                  {error.message}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 1 }}>
            <ReCAPTCHA sitekey={envConfig.reCAPTCHASiteKey} onChange={handleRecaptchaChange} />
          </Box>
          <LoadingButton
            loading={isLoading}
            loadingPosition="end"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<CircularProgress size={20} />}
          >
            Sign Up
          </LoadingButton>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" onClick={openSignIn} sx={{ cursor: 'pointer' }}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Copyright sx={{ mt: 5 }} /> */}
    </Container>
  );
}

// import { QueryObserverResult } from '@tanstack/react-query';

export type Nullable<T> = T | null;

export type RequiredPick<T, K extends keyof T> = Required<Pick<T, K>>;

export type BaseQueryResult<T> = {
  isLoading: boolean;
  isError: boolean;
  error?: Nullable<Error>;
  data?: T;
};

export type QueryResult<T> = BaseQueryResult<T> & {
  isFetching: boolean;
  data?: T;
  // refetch: () => Promise<QueryObserverResult<T, Error>>;
  refetch: () => void;
};

// allow start with +
// allow - and spaces
// at least 5 gigits
export const phoneRegex = /^\+?(?=.*\d.*\d.*\d.*\d.*\d)[0-9\s-]+$/;

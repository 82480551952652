import { Nullable } from '@src/common-utils/Models';
import axiosInstance, { userEndpointPrefix } from '@src/libs/axios-util';

export const updateProfile = async (
  accessToken: string,
  profile: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address?: Nullable<string>;
  },
): Promise<void> => {
  await axiosInstance.put<void>(`${userEndpointPrefix}/profiles`, { accessToken, ...profile });
};

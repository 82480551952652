import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClinicDTO, CreateClinicDTO } from '@src/common-utils/DataModels';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Nullable, phoneRegex } from '@src/common-utils/Models';
import { LoadingButton } from '@mui/lab';
import { useCreateClinicQuery, useUpdateClinicQuery } from '@src/queries/ClinicQuery';
import CloseIcon from '@mui/icons-material/Close';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

export type ClinicDialogProps = {
  mode: 'CREATE' | 'UPDATE';
  initialValue?: Nullable<ClinicDTO>;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  onClose: () => void;
  open: boolean;
};

export const ClinicDialog = ({ open, ...rest }: ClinicDialogProps) => {
  const handleClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick') {
      rest.onClose();
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} aria-labelledby="responsive-dialog-title" onClose={handleClose}>
      <ClinicDetail {...rest} />
    </Dialog>
  );
};

const schema = yup.object({
  name: yup.string().required('Clinic name is required'),
  phoneNumber: yup
    .string()
    .required('Phone number required')
    .test('phone-validate', 'Invalid phone number', (value: string | undefined) => {
      if (!value || !value.trim()) return false;
      return phoneRegex.test(value.trim());
    }),
  address: yup.string().required('Address is required'),
});

export const ClinicDetail = ({ mode, initialValue, onError, onSuccess, onClose }: Omit<ClinicDialogProps, 'open'>) => {
  const [error, setError] = useState<Nullable<Error>>(null);

  const { createClinic } = useCreateClinicQuery({
    onError: (error: Error) => {
      setError(error);
      if (onError) {
        onError(error);
      }
    },
    onSuccess: async () => {
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const { updateClinic } = useUpdateClinicQuery({
    onError: (error: Error) => {
      setError(error);
      if (onError) {
        onError(error);
      }
    },
    onSuccess: async () => {
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting, isValid, dirtyFields },
    // setValue,
    // reset,
  } = useForm<CreateClinicDTO>({
    defaultValues: {
      name: initialValue?.name,
      phoneNumber: initialValue?.phoneNumber,
      address: initialValue?.address,
      addOwnerAsMember: true,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: CreateClinicDTO) => {
    if (mode === 'CREATE') {
      createClinic(data);
    } else {
      updateClinic({ clinicId: initialValue?.id || '', createClinicData: data });
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <Box sx={{ width: '100%', display: ' flex', justifyContent: 'space-between', alignItems: 'top' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <LocalHospitalIcon />
            <Typography variant="h5">{mode === 'CREATE' ? 'Add' : 'Update'} Clinic</Typography>
          </Box>
          <IconButton size="medium" onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1">{userInfo?.email}</Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="name"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="phoneNumber"
                    label="Phone"
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="address"
                    label="Address"
                    error={Boolean(errors.address)}
                    helperText={errors.address?.message || ''}
                    multiline
                    rows={2}
                  />
                )}
              />
            </Grid>
            {mode === 'CREATE' && (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="addOwnerAsMember"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        defaultChecked={true}
                        color="primary"
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  <Typography component="h4" variant="body1">
                    Add myself as the member
                  </Typography>
                </Box>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Typography component="h4" variant="body2" color="error">
                  {error.message}
                </Typography>
              </Grid>
            )}
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            loadingPosition="end"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isDirty || isSubmitting || !isValid || Object.keys(dirtyFields).length === 0}
            disableElevation
            endIcon={<CircularProgress size={isSubmitting ? 20 : 0} />}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

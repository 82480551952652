import { Link, SxProps, Theme, TypographyOwnProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MouseEvent } from 'react';

type HeaderLink = {
  variant?: TypographyOwnProps['variant'];
  text: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  sx?: SxProps<Theme>;
};

export const HeaderLink = ({ text, variant = 'body2', onClick, sx }: HeaderLink) => {
  return (
    <Link
      component="a"
      variant={variant}
      underline="hover"
      sx={{
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: grey[400],
        },
        ...sx,
        cursor: 'pointer',
      }}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      {text}
    </Link>
  );
};

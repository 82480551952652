import { Box, CircularProgress } from '@mui/material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { ClinicMemberStatus, MemberClinicDTO, StatusDisplay } from '@src/common-utils/DataModels';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';

export const MemeberStatusCellRenderer = ({
  data,
  context: { changeStatus },
}: {
  data?: MemberClinicDTO;
  context: {
    changeStatus: (dto: MemberClinicDTO, newStatus: ClinicMemberStatus) => void;
  };
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChangeStatus = (status: ClinicMemberStatus) => {
    if (data) {
      setIsUpdating(true);
      changeStatus(data, status);
    }
  };

  useEffect(() => setIsUpdating(false), []);

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        {data ? StatusDisplay[data.status] : ''}
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', alignItems: ' center', justifyContent: 'flex-end' }}>
          {!isUpdating && (
            <>
              {data?.status && ClinicMemberStatus.ACCEPTED !== data?.status && (
                <IconButton size="medium" onClick={() => handleChangeStatus(ClinicMemberStatus.ACCEPTED)}>
                  <Tooltip title="Accept" arrow enterDelay={500}>
                    <CheckCircleOutlineIcon fontSize="small" color="success" />
                  </Tooltip>
                </IconButton>
              )}
              {ClinicMemberStatus.PENDING === data?.status && (
                <IconButton size="medium" onClick={() => handleChangeStatus(ClinicMemberStatus.DECLINED)}>
                  <Tooltip title="Decline" arrow enterDelay={500}>
                    <HighlightOffIcon fontSize="small" color="error" />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
          {isUpdating && <CircularProgress size={16} />}
        </Box>
      </Grid>
    </Grid>
  );
};

import { Route, Routes } from 'react-router-dom';
import { MainBody } from '@src/layout/MainBody';
import SignIn from '@src/components/user/SignIn';
import SignUp from '@src/components/user/SignUp';
import { PasswordResetSuccessPage, SignupSuccessPage } from '@src/components/user/SuccessPages';
import { ForgotPassword, ResetPassword } from '@src/components/user/ResetPassword';
import { withAuth } from '@src/components/common/ProtectedPage';
import { Profile } from '@src/components/user/Profile';
import { UserManagement } from '@src/components/user-management/UserMangement';
import { ClinicManagement } from '@src/components/clinic/clinic-management/ClinicManagement';
import { MyClinic } from '@src/components/clinic/my-clinic/MyClinic';
import { CognitoGroup } from '@src/common-utils/AuthModels';

export const ROUTES = {
  home: '/',
  signin: '/signin',
  signup: '/signup',
  signupSuccess: '/signup-success',
  forgotPassowrd: '/forgot-password',
  resetPassword: '/reset-password',
  profile: '/profile',
  passwordResetSuccess: '/reset-success',
  userManagement: '/user-management',
  clinicManagement: '/clinic-management',
  myClinic: '/my-clinic',
};

const ProtectedProfile = withAuth(Profile);
const ProtectedUserManagement = withAuth(UserManagement, {
  redirect: ROUTES.userManagement,
  groups: [CognitoGroup.SystemAdmin],
});
const ProtectedClinicManagement = withAuth(ClinicManagement, {
  redirect: ROUTES.clinicManagement,
  groups: [CognitoGroup.SystemAdmin, CognitoGroup.Admin],
});
const ProtectedMyClinic = withAuth(MyClinic, { redirect: ROUTES.myClinic });

export const RoutePage = () => (
  <Routes>
    <Route path={ROUTES.home} element={<MainBody />} />
    <Route path={ROUTES.signin} element={<SignIn />} />
    <Route path={ROUTES.signup} element={<SignUp />} />
    <Route path={ROUTES.signupSuccess} element={<SignupSuccessPage />} />
    <Route path={ROUTES.forgotPassowrd} element={<ForgotPassword />} />
    <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
    <Route path={ROUTES.profile} element={<ProtectedProfile />} />
    <Route path={ROUTES.passwordResetSuccess} element={<PasswordResetSuccessPage />} />
    <Route path={ROUTES.userManagement} element={<ProtectedUserManagement />} />
    <Route path={ROUTES.clinicManagement} element={<ProtectedClinicManagement />} />
    <Route path={ROUTES.myClinic} element={<ProtectedMyClinic />} />
    <Route path="*" element={<MainBody />} />
  </Routes>
);

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container } from '@mui/system';
import { Nullable, phoneRegex } from '@src/common-utils/Models';
import { AuthStatus, ServerError, isAuthError } from '@src/common-utils/AuthModels';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { CircularProgress, Grid, Link, TextField, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LoadingButton } from '@mui/lab';
import { ROUTES } from '@src/layout/RoutePage';
import { refreshTokenAuth } from '@src/queries/RefreshTokenQuery';
import { getStoreAuthInfo, storeAuthInfo } from '@src/common-utils/StoreUtil';
// import { updateProfile } from '@src/libs/cognito-client';
import { updateProfile } from '@src/queries/UserQuery';

const schema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().required('Address is required'),
  phoneNumber: yup
    .string()
    .required('Phone number required')
    .test('phone-validate', 'Invalid phone number', (value: string | undefined) => {
      if (!value || !value.trim()) return false;
      return phoneRegex.test(value.trim());
    }),
});

type ProfileFormData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
};

export const Profile = () => {
  const navigate = useNavigate();

  const { userInfo, getAuthStatus, refreshUserInfo, logout } = useUserContext();

  const [error, setError] = useState<Nullable<Error>>(null);
  const [successMessage, setSuccessMessage] = useState('');

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting, isValid, dirtyFields },
    setValue,
    reset,
  } = useForm<ProfileFormData>({
    defaultValues: {
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      phoneNumber: userInfo?.phoneNumber,
      address: userInfo?.address || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (userInfo) {
      setValue('firstName', userInfo.firstName || '');
      setValue('lastName', userInfo.lastName || '');
      setValue('phoneNumber', userInfo.phoneNumber || '');
      setValue('address', userInfo.address || '');
    }
  }, [setValue, userInfo]);

  const onSubmit = async (data: ProfileFormData) => {
    const authStatus = getAuthStatus();

    if (authStatus === AuthStatus.EXPIRED) {
      navigate(ROUTES.signin);
      return;
    }

    if (authStatus === AuthStatus.LAST_ACCESS_VALID) {
      try {
        await refreshTokenAuth();
      } catch (_e) {
        navigate(ROUTES.signin);
        return;
      }
    }

    const { firstName, lastName, address, phoneNumber } = data;
    const authInfo = getStoreAuthInfo();
    if (authInfo) {
      try {
        await updateProfile(authInfo.accessToken!, {
          firstName,
          lastName,
          address,
          phoneNumber: phoneNumber.trim(),
        });

        reset();
        setError(null);
        setSuccessMessage('Your profile has been updated successfuly');
        storeAuthInfo({
          ...authInfo,
          userInfo: { ...authInfo.userInfo, firstName, lastName, address, phoneNumber },
        });
        refreshUserInfo();
      } catch (e) {
        setSuccessMessage('');
        setError(e instanceof ServerError ? e : new Error('Unexpected error'));
        if (isAuthError(e)) {
          await logout();
          navigate(ROUTES.signin);
        }
      }
    } else {
      navigate(ROUTES.signin);
      return;
    }
  };

  const gotToHome = () => navigate(ROUTES.home);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <AccountCircleIcon />
          <Typography component="h1" variant="h5">
            My Profile
          </Typography>
        </Box>

        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1">{userInfo?.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue={userInfo?.firstName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                defaultValue={userInfo?.lastName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoComplete="family-name"
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="phoneNumber"
                    label="Phone"
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="address"
                    label="Address"
                    multiline
                    rows={2}
                    maxRows={4}
                  />
                )}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography component="h4" variant="body2" color="error">
                  {error.message}
                </Typography>
              </Grid>
            )}
            {successMessage && (
              <Grid item xs={12}>
                <Typography component="h4" variant="body2" color="success" sx={{ color: 'blue' }}>
                  {successMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            loadingPosition="end"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isDirty || isSubmitting || !isValid || Object.keys(dirtyFields).length === 0}
            disableElevation
            endIcon={<CircularProgress size={isSubmitting ? 20 : 0} />}
          >
            Submit
          </LoadingButton>
        </Box>
        <Grid container justifyContent="flex-start"></Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link variant="body2" onClick={gotToHome} sx={{ cursor: 'pointer' }}>
              Already updated profile? Home
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

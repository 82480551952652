// const userPoolId = import.meta.env.VITE_APP_USERPOOL_ID;
// const clientId = import.meta.env.VITE_APP_CLIENT_ID;
// const region = import.meta.env.VITE_AWS_REGION || 'ap-southeast-2';
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
const reCAPTCHASiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const envConfig = {
  // userPoolId,
  // clientId,
  // region,
  apiEndpoint,
  reCAPTCHASiteKey,
};

export default envConfig;

import { getStoreAuthInfo, storeAuthInfo } from '@src/common-utils/StoreUtil';
import envConfig from '@src/env';
import { AuthInfo } from '@src/common-utils/AuthModels';

export const setRefreshTokenInCookie = async (refreshToken: string) => {
  const authInfo = getStoreAuthInfo();

  await fetch(`${envConfig.apiEndpoint}/v1/auth/refresh-token`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authInfo?.idToken}`,
    },
    body: JSON.stringify({
      refreshToken,
    }),
    credentials: 'include',
  });

  // axios cros cookie not working
  // await axiosInstance.post(
  //   '/v1/auth/refresh-token',
  //   {
  //     refreshToken,
  //   },
  //   // { withCredentials: true },
  // );
};

export const refreshTokenAuth = async () => {
  const response = await fetch(`${envConfig.apiEndpoint}/v1/auth/refresh-token`, {
    mode: 'cors',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (response.status > 300) {
    throw new Error('Refresh token failed');
  }

  const authInfo = (await response.json()) as AuthInfo;
  storeAuthInfo(authInfo);
};

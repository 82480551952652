import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Nullable } from '@src/common-utils/Models';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { ROUTES } from '@src/layout/RoutePage';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { LinkLoadingButton } from '@src/components/common/LinkLoadingButton';
import ReCAPTCHA from 'react-google-recaptcha';
import envConfig from '@src/env';
import { verifyReCaptchaToken } from '@src/queries/VerifyRecaptchaQuery';

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
});

type ForgotPasswordFormData = {
  email: string;
};

export const ForgotPassword = () => {
  const { state } = useLocation();
  const email = state?.email;

  const navigate = useNavigate();

  const { isLoading, sendResetPasswordCode } = useUserContext();

  const [error, setError] = useState<Nullable<Error>>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({ resolver: yupResolver(forgotPasswordSchema) });

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  // Handle the reCAPTCHA onChange event
  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
  };

  const onSubmit = async (data: ForgotPasswordFormData) => {
    if (!recaptchaValue) {
      setError(new Error('Please complete the reCAPTCHA check to provide that you are not a robot'));
      return;
    }

    try {
      await verifyReCaptchaToken(recaptchaValue);
      setError(null);
    } catch (e) {
      setError(new Error('reCAPTCHA verify failed!'));
      return;
    }

    sendResetPasswordCode({
      email: data.email,
      onSuccess: () => {
        setError(null);
        navigate(ROUTES.resetPassword, {
          state: {
            email: data.email,
          },
        });
      },
      onError: (error: Error) => setError(error),
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" align="center" sx={{ color: 'primary', mb: '8px' }}>
          Trouble signing in?
        </Typography>
        <Typography component="span" variant="body2">
          Don't worry, we will get you back! Just enter your email address and we'll send you a code that you can use to
          reset your password.
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              error={Boolean(errors.email)}
              helperText={errors.email?.message || ''}
              value={email}
            />
          )}
        />
        {error && (
          <Typography component="h4" variant="body2" color="error">
            {error.message}
          </Typography>
        )}
        <ReCAPTCHA sitekey={envConfig.reCAPTCHASiteKey} onChange={handleRecaptchaChange} />
        <LoadingButton
          loading={isLoading}
          loadingPosition="end"
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          endIcon={<CircularProgress size={isLoading ? 20 : 0} />}
        >
          Submit
        </LoadingButton>
      </Box>
    </Container>
  );
};

const resetPasswordSchema = yup.object().shape({
  code: yup.string().required('Reset password code is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .max(32),
  confirmPassword: yup
    .string()
    .required('Confirm passowrd is required')
    .oneOf([yup.ref('password'), ''], `Passwords don't match`),
});

type ResetPasswordFormData = {
  code: string;
  password: string;
  confirmPassword: string;
};

export const ResetPassword = () => {
  const navigate = useNavigate();

  const { resetPassword, isLoading, sendResetPasswordCode } = useUserContext();

  const location = useLocation();

  const initialLocationState = useRef(location.state);
  const email = initialLocationState.current?.email;

  const [error, setError] = useState<Nullable<Error>>(null);

  const [resendCodeMessage, setResendCodeMessage] = useState<Nullable<string>>(null);
  const [resendCodeError, setResendCodeError] = useState<Nullable<Error>>(null);

  const [action, setAction] = useState<Nullable<string>>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({ resolver: yupResolver(resetPasswordSchema) });

  const onSubmit = async (data: ResetPasswordFormData) => {
    setAction('resetPassword');
    await resetPassword({
      confirmationCode: data.code,
      email,
      newPassword: data.password,
      onSuccess: () => {
        navigate(ROUTES.passwordResetSuccess, {
          state: {
            email,
          },
        });
      },
      onError: (error: Error) => setError(error),
    });
  };

  const resendCode = async () => {
    setAction('resendCode');

    sendResetPasswordCode({
      email,
      onSuccess: () => {
        setResendCodeError(null);
        setResendCodeMessage(`Code was resent to ${email}`);
      },
      onError: (error: Error) => {
        setResendCodeMessage(null);
        setResendCodeError(error);
      },
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: '8px' }} align="center">
          Reset Password
        </Typography>
        <Typography component="span" variant="body2">
          We have sent you a reset password code to {email}, please provide this code to reset your new password.
          <LinkLoadingButton
            label="Resend code"
            isLoading={action === 'resendCode' && isLoading}
            sx={{ ml: '4px' }}
            onClick={resendCode}
          />
        </Typography>
        {resendCodeMessage && (
          <Typography component="span" variant="body2" sx={{ color: 'blue', mt: '4px' }}>
            {resendCodeMessage}
          </Typography>
        )}
        {resendCodeError && (
          <Typography component="span" variant="body2" sx={{ color: 'red', mt: '4px' }}>
            {`Error: ${resendCodeError.message}`}
          </Typography>
        )}
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="code"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Code"
                    autoComplete="code"
                    autoFocus
                    error={Boolean(errors.code)}
                    helperText={errors.code?.message || ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((preShow) => !preShow)}
                            onMouseDown={(e) => e.preventDefault()}
                            size="small"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfirmPassword((preShow) => !preShow)}
                            onMouseDown={(e) => e.preventDefault()}
                            size="small"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography component="h4" variant="body2" color="error">
                  {error.message}
                </Typography>
              </Grid>
            )}
          </Grid>
          <LoadingButton
            loading={isLoading}
            loadingPosition="end"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<CircularProgress size={isLoading ? 20 : 0} />}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

import { useMediaQuery, useTheme } from '@mui/material';
import { Alert, Box, Container, Dialog, DialogProps, Grid, IconButton, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { caseInsensitiveComparator } from '@src/common-utils/Util';
import { useClinicMemberQuery, useDeleteClinicMemberQuery } from '@src/queries/ClinicQuery';
import { ClinicDTO, ClinicMemberDTO, StatusDisplay } from '@src/common-utils/DataModels';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useConfirm } from 'material-ui-confirm';
import { useNotificationContext } from '@src/contexts/NotificationProvider';

const ClinicMemberStatusCellRendener = ({ data }: { data: ClinicMemberDTO }) => {
  const confirm = useConfirm();
  const { showMessage } = useNotificationContext();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotification = (message: string, isSuccess = true) => {
    showMessage(message, {
      position: isSmall ? 'top-center' : undefined,
      direction: isSmall ? 'down' : undefined,
      variant: isSuccess ? 'success' : 'error',
    });
  };

  const { deleteClinicMember } = useDeleteClinicMemberQuery({
    onSuccess: async () => showNotification('Clinic member was removed successfully'),
    onError: (error: Error) => showNotification(error.message, false),
  });

  const handleRemoveMember = () => {
    confirm({ title: 'Remove Member', content: 'Are you sure you want to remove this member?' })
      .then(() => {
        deleteClinicMember({ clinicId: data.clinicId, memberId: data.member.id });
      })
      .catch(() => {
        // np op
      });
  };
  return (
    <Grid container alignItems="center">
      <Grid item xs={9}>
        {StatusDisplay[data.status]}
      </Grid>
      <Grid item xs={3}>
        <IconButton size="medium" onClick={handleRemoveMember}>
          <DeleteForeverIcon fontSize="medium" color="warning" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const colDefs: ColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    minWidth: 100,
    flex: 2,
    sort: 'asc',
    valueGetter: ({ data }: ValueGetterParams<ClinicMemberDTO, string>) => data?.member.email,
    comparator: caseInsensitiveComparator,
  },
  {
    field: 'status',
    headerName: 'Status',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    maxWidth: 120,
    flex: 2,
    cellRenderer: ClinicMemberStatusCellRendener,
  },
];

export type ClinicMembersDialogProps = {
  onClose: () => void;
  open: boolean;
  clinic: ClinicDTO;
};

export const ClinicMembersDialog = ({ open, onClose, clinic }: ClinicMembersDialogProps) => {
  const handleClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick') {
      onClose();
    }
  };

  const { data } = useClinicMemberQuery({ clinicId: clinic.id });

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} aria-labelledby="responsive-dialog-title" onClose={handleClose}>
      <Container
        component="main"
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '500px',
          minHeight: '200px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{ width: '100%', mb: 1, mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">{clinic.name}</Typography>
          <IconButton size="medium" onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="ag-theme-quartz" component="div" sx={{ height: '500px', width: '100%', pb: 1 }}>
          {data && data.length > 0 && <AgGridReact rowData={data} columnDefs={colDefs} />}
          {(!data || data.length === 0) && (
            <Alert severity="info" sx={{ mt: 2 }}>
              No clinic member
            </Alert>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

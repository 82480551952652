import { Box, Button, Card, CardContent, CardHeader, Container, Typography } from '@mui/material';
import { ClinicDTO } from '@src/common-utils/DataModels';
import { ClinicMenuRenderer } from '@src/components/clinic/clinic-management/menu/ClinicMenuRenderer';
import { ClinicMenItem } from '@src/components/clinic/clinic-management/menu/ClinicMenu';
// import StarIcon from '@mui/icons-material/Star';
// import StarBorderIcon from '@mui/icons-material/StarBorder';

const contentWidth = 360;

export const ClinicManagementMobile = ({
  data,
  onAddClinic,
  handleSelectClinicMenu,
}: {
  data?: ClinicDTO[];
  onAddClinic: () => void;
  handleSelectClinicMenu: (acton: ClinicMenItem, data: ClinicDTO) => void;
}) => {
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
        height: '100vh',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ width: contentWidth, position: 'fixed', top: 64, zIndex: 1, backgroundColor: 'white', pt: 1 }}>
        <Button size="medium" variant="contained" color="primary" onClick={onAddClinic} sx={{ textTransform: 'none' }}>
          Add Clinic
        </Button>
      </Box>
      <Box sx={{ width: contentWidth, display: 'flex', flexDirection: 'column', gap: 2, pt: 6 }}>
        {data?.map((clinic) => (
          <Card sx={{ width: contentWidth }} key={`clinic-${clinic.id}`}>
            <CardHeader
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6">{clinic.name}</Typography>
                  <ClinicMenuRenderer
                    data={clinic}
                    context={{
                      handleSelectClinicMenu,
                    }}
                  />
                  {/* <IconButton>
                  {clinic.isDefault && <StarIcon fontSize="medium" color="primary" />}
                  {!clinic.isDefault && <StarBorderIcon fontSize="medium" />}
                </IconButton> */}
                </Box>
              }
              subheader={clinic.phoneNumber}
            />
            <CardContent sx={{ flexGrow: 1, maxHeight: 120, overflow: 'auto', mt: 0, pt: 0 }}>
              <Typography variant="body1">{clinic.address}</Typography>
            </CardContent>
            {/* <Divider sx={{ mr: 1, ml: 1 }} /> */}
            {/* <CardActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton size="large">
              <EditNoteIcon fontSize="medium" color="primary" onClick={() => onEditClinic(clinic)} />
            </IconButton>
            <IconButton size="medium" onClick={() => onDeleteClinic(clinic.id)}>
              <DeleteForeverIcon fontSize="medium" color="warning" />
            </IconButton>
          </CardActions> */}
          </Card>
        ))}
      </Box>
    </Container>
  );
};

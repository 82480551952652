import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Products } from '@src/components/product/Products';

export const MainBody = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const titleHeader = isSmall ? 'h5' : 'h4';

  return (
    <main>
      {/* <Box sx={{ display: 'flex', flexDirection: 'column', pl: '16px', pr: '16px', pt: '4px' }}>body here</Box> */}
      <Box sx={{ bgcolor: 'background.paper', pt: 4, pb: 4 }}>
        <Container maxWidth={isSmall ? 'sm' : 'md'}>
          <Typography variant={titleHeader} align="center" color="text.primary" gutterBottom>
            Welcome to GuruHealth
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '6px' }}>
            <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ textAlign: 'left' }}>
              Discover the latest advancements in dental technology and equipment right here. Whether you're a seasoned
              dentist or just starting your dental practice, we have extensive range you need to provide top-notch oral
              care to your patients.
            </Typography>
            <Box sx={{ mb: '4px' }}>
              <Typography variant="body1" sx={{ mb: 0 }}>
                Why choose us?
              </Typography>
              <ul style={{ paddingLeft: '12px', marginTop: '4px' }}>
                <li style={{ marginBottom: '2px' }}>
                  <strong>Quality Assurance:</strong> We only offer high quality products for their excellence in the
                  dental industry.
                </li>
                <li style={{ marginBottom: '2px' }}>
                  <strong>Affordable Pricing:</strong> Enjoy competitive prices without compromising on quality.
                </li>
                <li style={{ marginBottom: '2px' }}>
                  <strong>Expert Guidance:</strong> Our team is here to assist you in finding the perfect equipment to
                  suit your practice's needs.
                </li>
                <li>
                  <strong>Fast Shipping:</strong> We understand the urgency of setting up your practice, so we offer
                  fast and reliable shipping options.
                </li>
              </ul>
            </Box>
            <Typography variant="body1" align="center" color="text.secondary" sx={{ textAlign: 'left' }}>
              Browse our selection today and equip your dental practice with the tools and technology that will set you
              apart. Your patients deserve the best, and we're here to help you deliver it. Ready to get started?
              Explore our products and take the first step towards elevating your dental practice to new heights.
            </Typography>
          </Box>

          {/* <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
            <Button variant="contained">Main call to action</Button>
            <Button variant="outlined">Secondary action</Button>
          </Stack> */}
        </Container>
      </Box>
      <Container sx={{ py: 2 }} maxWidth="md">
        <Products />
      </Container>
      {/* <Grid container justifyContent="center">
        <Grid item>
          <Container sx={{ py: 8 }} maxWidth="md">
            <Products />
          </Container>
        </Grid>
      </Grid> */}
    </main>
  );
};

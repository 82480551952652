import { createContext, useState, ReactNode, useContext, forwardRef } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Slide, SlideProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

type SnackbarContextValueProps = {
  showMessage: (message: string, props?: MessageProps) => void;
};

const SnackbarContext = createContext<SnackbarContextValueProps>({
  showMessage: () => {
    //no op
  },
});

type SnackbarProviderProps = {
  children: ReactNode;
};

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type MessageProps = {
  position?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
  direction?: SlideProps['direction'];
  variant?: AlertProps['severity'];
  duration?: number;
};

type SnackbarState = {
  message: string;
  duration?: number;
  anchor: SnackbarOrigin;
  variant: AlertProps['severity'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Transition: React.JSXElementConstructor<TransitionProps & { children: React.ReactElement<any, any> }>;
};

const defaultDuration = 5000;
const defaultPosition = 'top-right';
const defaultDirection = 'left';
const defaultVariant = 'info';

export const NotificationProvider = ({ children }: SnackbarProviderProps) => {
  const [open, setOpen] = useState(false);

  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    message: '',
    duration: defaultDuration,
    anchor: { vertical: 'top', horizontal: 'right' },
    variant: 'info',
    Transition: Slide,
  });

  const mapAnchor = (position: MessageProps['position']): SnackbarOrigin => {
    const [vertical, horizontal] = position ? position.split('-') : ['top', 'right'];
    return {
      vertical,
      horizontal,
    } as SnackbarOrigin;
  };

  const showMessage = (
    message: string,
    {
      direction = defaultDirection,
      position = defaultPosition,
      variant = defaultVariant,
      duration = defaultDuration,
    }: MessageProps = {
      direction: defaultDirection,
      position: defaultPosition,
      variant: defaultVariant,
      duration: defaultDuration,
    },
  ) => {
    setSnackbarState({
      message,
      duration,
      anchor: mapAnchor(position),
      variant,
      Transition: (props) => <Slide {...props} direction={direction} />,
    });
    setOpen(true);
  };

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={snackbarState.duration}
        onClose={handleClose}
        anchorOrigin={snackbarState.anchor}
        transitionDuration={400}
        TransitionComponent={snackbarState.Transition}
      >
        <Alert onClose={handleClose} severity={snackbarState.variant} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useNotificationContext = () => useContext(SnackbarContext);

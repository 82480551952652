import { Box, Divider, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Nullable } from '@src/common-utils/Models';
import { CartItem } from '@src/common-utils/DataModels';
import { useCartContext } from '@src/contexts/CartContextProvider';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useEffect, useState } from 'react';
import { ReloadableImage } from '@src/components/common/ReloadableImage';
// import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { QuantityInput } from '@src/components/common/NumberInput';
import { currencyFormatter } from '@src/common-utils/Util';

const CartBodyItem = ({
  cartItem,
  itemIndex, // product,
}: {
  cartItem: CartItem;
  itemIndex: number;
  // product: ProductDTO;
}) => {
  const { removeCartItem, setQuantity } = useCartContext();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // const { data: product, refetch } = useProductQuery({ productCode: cartItem.productCode, lazy: true });

  // const [isError, setIsError] = useState(false);

  // const [imageURL, setImageURL] = useState<string | undefined>(cartItem.imageURL);

  // const onError = () => {
  //   setIsError(true);
  // };

  // useEffect(() => {
  //   if (!cartItem.imageURL) {
  //     refetch();
  //   }
  // }, [cartItem.imageURL, refetch]);

  // useEffect(() => {
  //   console.log('product', product);
  //   if (product) {
  //     setIsError(false);
  //     setImageURL(product.images[0].url);
  //   }
  // }, [product]);

  // useEffect(() => {
  //   if (isError) {
  //     refetch();
  //   }
  // }, [isError, refetch]);

  const onQuantityChange = (newQuantity?: number) => {
    if (!newQuantity) {
      removeCartItem(itemIndex);
      return;
    }
    setQuantity(itemIndex, newQuantity);
  };

  return (
    <Box
      component={'div'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        maxHeight: '240px',
        minHeight: '100px',
      }}
    >
      <ReloadableImage url={cartItem.imageURL} width="72px" />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography component="h1" variant="subtitle2" sx={{ fontWeight: '600' }}>
          {cartItem.name}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle2">Product code: {cartItem.productCode}</Typography>
          <IconButton size="small" onClick={() => removeCartItem(itemIndex)}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>

        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={isSmall ? 6 : 4}>
            <Typography variant="body1">
              ${cartItem.price}/{cartItem.unit}
            </Typography>
          </Grid>
          {!isSmall && (
            <Grid item xs={4} container justifyContent="center">
              <QuantityInput
                value={cartItem.quantity}
                min={0}
                max={99}
                // onChange={onQuantityChange}
                onDecrement={() => onQuantityChange(cartItem.quantity - 1)}
                onIncrement={() => onQuantityChange(cartItem.quantity + 1)}
              />
            </Grid>
          )}
          <Grid item xs={isSmall ? 6 : 4} container justifyContent="flex-end">
            <Typography variant="body1" sx={{ ml: '4px', fontWeight: '500' }}>
              {currencyFormatter.format(cartItem.itemTotals)}
            </Typography>
          </Grid>
        </Grid>
        {isSmall && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={4} container justifyContent="center">
              <QuantityInput
                value={cartItem.quantity}
                min={0}
                max={99}
                // onChange={onQuantityChange}
                onDecrement={() => onQuantityChange(cartItem.quantity - 1)}
                onIncrement={() => onQuantityChange(cartItem.quantity + 1)}
              />
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

// const mapImageURLs = (products?: ProductDTO[]): Record<string, string> => {
//   return products
//     ? products.reduce<Record<string, string>>((map, p) => {
//         map[p.code] = p.images[0].url;
//         return map;
//       }, {})
//     : {};
// };

export const CartBody = () => {
  const { myCart } = useCartContext();

  const [cartItems, setCartItems] = useState<Nullable<CartItem[]>>(null);

  // useEffect(() => {
  //   // const cacheProducts = queryClient.getQueryData<Product[]>(productsQueryKey);
  //   const cacheProductsPages = queryClient.getQueryData<InfiniteData<ProductsQueryResponse>>(productsInfiniteQueryKey);
  //   const cacheProducts = cacheProductsPages?.pages.flatMap((page) => page.products);
  //   if (cacheProducts) {
  //     const caheImageURLMap = mapImageURLs(cacheProducts);
  //     const newItems = cartItems.map((item) => ({ ...item, imageURL: caheImageURLMap[item.productCode] }));
  //     setItems(newItems);
  //   }
  // }, [queryClient, cartItems]);

  useEffect(() => {
    if (!myCart || myCart.items.length === 0) {
      setCartItems(null);
      return;
    }

    setCartItems(myCart.items);
  }, [myCart, myCart?.items]);

  return (
    <>
      {(!cartItems || cartItems.length === 0) && (
        <Typography component="h1" variant="subtitle1" sx={{ mr: '16px', fontStyle: 'italic' }}>
          There are no products in the cart
        </Typography>
      )}
      {cartItems && cartItems.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          {cartItems.map((item, i) => {
            return (
              <Box
                key={`${item.productCode}-${i}`}
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
              >
                <CartBodyItem cartItem={item} itemIndex={i} />
                <Divider light />
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

import { Nullable } from '@src/common-utils/Models';
import { AuthInfo, AuthStatus } from '@src/common-utils/AuthModels';
import { getEpochTime } from '@src/common-utils/Util';

export const TOKEN_EXPIRATION_MARGIN_SECONDS = 10;
export const TOKEN_TTL = 15 * 60 - TOKEN_EXPIRATION_MARGIN_SECONDS;

export const authStorageKey = 'dentalware_auth_info';

export const lastAccesStorageKey = 'dentalware_last_access';

export const storeAuthInfo = (authInfo: AuthInfo) => localStorage.setItem(authStorageKey, JSON.stringify(authInfo));

export const clearAuthInfo = () => localStorage.removeItem(authStorageKey);

export const getStoreAuthInfo = (): Nullable<AuthInfo> => {
  const authInfoString = localStorage.getItem(authStorageKey);
  if (!authInfoString) {
    return null;
  }

  return JSON.parse(authInfoString);
};

// use httpOnly cookie, no need this
// const refreshTokenCookieName = 'refreshToken';
// export const getRefreshTokenFromCookie = (): Nullable<string> => {
//   const cookies = document.cookie.split(';');
//   for (const cookie of cookies) {
//     const [name, value] = cookie.trim().split('=');
//     if (name === refreshTokenCookieName) {
//       return value;
//     }
//   }
//   return null;
// };

// export const removeRefreshTokenFromCookie = () =>
//   (document.cookie = `${refreshTokenCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`);

export const getLastAccess = (): Nullable<number> => {
  const lastAccess = localStorage.getItem(lastAccesStorageKey);
  return lastAccess ? Number(lastAccess) : null;
};

export const touchLastAccess = () => {
  localStorage.setItem(lastAccesStorageKey, String(getEpochTime()));
};

export const resetLastAccess = () => {
  localStorage.removeItem(lastAccesStorageKey);
};

export const clearLocalStore = () => {
  clearAuthInfo();
  resetLastAccess();
};

export const checkAuthenticated = (): AuthStatus => {
  const authInfo = getStoreAuthInfo();

  if (!authInfo) {
    return AuthStatus.EXPIRED;
  }

  const currentTime = getEpochTime();
  if (authInfo.tokenExpiration - TOKEN_EXPIRATION_MARGIN_SECONDS > currentTime) {
    return AuthStatus.TOKEN_VALID;
  }

  const lastAccess = getLastAccess();
  if (!lastAccess) {
    return AuthStatus.EXPIRED;
  }

  if (lastAccess > currentTime - TOKEN_TTL) {
    return AuthStatus.LAST_ACCESS_VALID;
  }

  return AuthStatus.EXPIRED;
};

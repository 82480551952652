import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  IconButton,
  SwipeableDrawer,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CartHeader } from '@src/components/cart/CartHeader';
import { useCartContext } from '@src/contexts/CartContextProvider';
import theme from '@src/theme';
import { CartBody } from '@src/components/cart/CartBody';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { useState } from 'react';
import { useCreateOrder } from '@src/queries/OrderQuery';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@src/layout/RoutePage';
import { Nullable } from '@src/common-utils/Models';
import { CreateOrderDTO, CreateOrderResponse } from '@src/common-utils/DataModels';
import { useConfirm } from 'material-ui-confirm';
import { currencyFormatter } from '@src/common-utils/Util';
import { AddressType, isAuthError } from '@src/common-utils/AuthModels';
import { ErrorColor, PrimaryColor } from '@src/components/common/Colors';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { DeliveryAddressDialog } from '@src/components/cart/DeliveryAddressDialog';
import DiscountIcon from '@mui/icons-material/Discount';

const OrderComfiramtionContent = ({ order }: { order: CreateOrderResponse }) => {
  return (
    <Alert severity="success">
      <AlertTitle>
        Your order number is <strong>{order.id}</strong>
      </AlertTitle>
      We have emailed your order confirmation, and will send you an update when your order has shipped.
    </Alert>
  );
};

const CartPanel = ({ open, onClose }: { open: boolean; onClose?: () => void }) => {
  const navigate = useNavigate();

  const confirm = useConfirm();

  const { userInfo, logout } = useUserContext();

  const { totals, subTotals, gst, myCart, discountCartItems, clearMyCart } = useCartContext();

  const [deliveryAddress, setDeliveryAddress] = useState(
    userInfo?.preferredDeliveryAddress?.address || userInfo?.address || '',
  );
  const [deliveryAddressType, setDeliveryAddressType] = useState<AddressType>(
    userInfo?.preferredDeliveryAddress?.addressType || 'YOUR_ADDRESS',
  );

  const [deliveryClinicId, setDeliveryClinicId] = useState<string | undefined>(
    userInfo?.preferredDeliveryAddress?.clinicId || '',
  );

  const [error, setError] = useState<Nullable<Error>>(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const hasCartItems = myCart && myCart?.items.length > 0;

  const handleDialogClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick') {
      setDialogOpen(false);
    }
  };

  const changeDeliveryAddress = (
    newDeliveryAddressType: AddressType,
    newAddressDeliveryAddress: string,
    newDeliverClinicId?: string,
  ) => {
    setDeliveryAddressType(newDeliveryAddressType);
    setDeliveryAddress(newAddressDeliveryAddress);
    setDeliveryClinicId(newDeliverClinicId);
    setDialogOpen(false);
  };

  const { createOrder, isLoading } = useCreateOrder({
    onSuccess: async (res: CreateOrderResponse) => {
      setError(null);
      clearMyCart();
      if (onClose) {
        onClose();
      }
      confirm({
        hideCancelButton: true,
        title: 'Thanks you for your order!',
        content: <OrderComfiramtionContent order={res} />,
      });
    },

    onError: (error: Error) => {
      if (error instanceof AxiosError) {
        if (isAuthError(error)) {
          logout();
          navigate(ROUTES.signin);
        } else {
          setError(error);
        }
      }
    },
  });

  const placeOrder = () => {
    if (hasCartItems && deliveryAddress) {
      const order: CreateOrderDTO = {
        deliveryAddress,
        deliveryClinicId,
        orderItems: myCart.items.map((item) => ({
          productCode: item.productCode,
          quantity: item.quantity,
        })),
      };
      createOrder(order);
    }
  };

  return (
    <>
      <CartHeader
        onClose={() => {
          if (onClose) onClose();
        }}
      />
      <Box sx={{ flexGrow: 1, padding: '8px', overflow: 'auto' }}>{open && <CartBody />}</Box>
      {hasCartItems && (
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#E5EFF6', padding: '4px 8px 4px 4px' }}>
          {discountCartItems && discountCartItems.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                mb: 0.5,
                flexGrow: 1,
                gap: '2px',
              }}
            >
              <IconButton>
                <DiscountIcon fontSize="medium" sx={{ color: ErrorColor }} />
              </IconButton>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5, flexGrow: 1 }}>
                {discountCartItems.map((discountCartItem) => (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    key={`discountCartItem-${discountCartItem.productCode}`}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500, color: ErrorColor, fontStyle: 'italic' }}>
                      {discountCartItem.productCode}({discountCartItem.description})
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500, color: ErrorColor }}>
                      -{currencyFormatter.format(discountCartItem.discountAmount)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', pl: '4px' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: PrimaryColor }}>
                Total: {currencyFormatter.format(totals)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500, color: PrimaryColor }}>
                GST: {currencyFormatter.format(gst)}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 500, color: PrimaryColor }}>
                +
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 500, color: PrimaryColor }}>
                {currencyFormatter.format(subTotals)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {error && (
        <>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Oops something went wrong, please try again later
          </Alert>
          <Divider light sx={{ mt: '8px' }} />
        </>
      )}
      <Box sx={{ mt: '4px', mb: '0', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mr: '2px', display: 'flex', alignItems: 'flex-start', mb: 0, flexDirection: 'column', pl: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Delivery Address:
            </Typography>
            <Tooltip title="Click to change delivery address" arrow placement="top">
              <IconButton size="small" onClick={() => setDialogOpen(true)}>
                <BorderColorIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          </Box>

          <Typography
            variant="body1"
            sx={{
              mt: 0,
              mb: '12px',
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {deliveryAddress}
          </Typography>
        </Box>
        {userInfo && open && (
          <LoadingButton
            loading={isLoading}
            loadingPosition="end"
            type="submit"
            fullWidth
            variant="contained"
            disabled={!hasCartItems || !deliveryAddress}
            color="primary"
            sx={{ borderRadius: 0 }}
            endIcon={<CircularProgress size={isLoading ? 20 : 0} />}
            onClick={placeOrder}
          >
            Place Order
          </LoadingButton>
        )}
      </Box>
      {userInfo && (
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={dialogOpen}
          aria-labelledby="responsive-dialog-title"
          onClose={handleDialogClose}
        >
          <DeliveryAddressDialog
            onClose={() => setDialogOpen(false)}
            onCancel={() => setDialogOpen(false)}
            onDeliveryAddressChange={changeDeliveryAddress}
            defaultDeliveryAddress={deliveryAddress}
            defaultDeliveryAddressType={deliveryAddressType}
            defaultDeliveryClinicId={deliveryClinicId}
            userInfo={userInfo}
          />
        </Dialog>
      )}
    </>
  );
};

type CartProps = { open: boolean; onClose?: () => void; onOpen?: () => void };
export const Cart = ({ open, onClose, onOpen }: CartProps) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (open && onOpen) {
      onOpen();
    } else if (!open && onClose) {
      onClose();
    }
  };

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
      <Box
        component={'div'}
        sx={{
          width: isSmall ? '345px' : '480px',
          // padding: '12px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CartPanel open={open} onClose={onClose} />
      </Box>
    </SwipeableDrawer>
  );
};

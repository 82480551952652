import { AuthInfo, SignUpRequest, SignUpResponse } from '@src/common-utils/AuthModels';
import axiosInstance, { authEndpointPrefix } from '@src/libs/axios-util';

// // const signInQueryKey = 'sign-in';
// export const useSignInQuery = (): {
//   signIn: (email: string, password: string) => Promise<void>;
// } & BaseQueryResult<AuthInfo> => {
//   const { mutate, isLoading, isError, error, data } = useMutation<
//     AuthInfo,
//     Error,
//     {
//       email: string;
//       password: string;
//     }
//   >(async ({ email, password }) => {
//     const res = await axiosInstance.post<AuthInfo>(authEndpointPrefix, { email, password });
//     return res.data;
//   });

//   const signIn = async (email: string, password: string) => {
//     mutate({ email, password });
//   };

//   const authInfo = !isError && data ? mapAuthInfo({ idToken: data.idToken, accessToken: data.accessToken }) : undefined;
//   return { isLoading, isError, error, data: authInfo, signIn };
// };

export const signInUser = async (email: string, password: string): Promise<AuthInfo> => {
  const res = await axiosInstance.post<AuthInfo>(`${authEndpointPrefix}/signin`, { email, password });
  return res.data;
};

export const signUpUser = async (request: SignUpRequest): Promise<SignUpResponse> => {
  const res = await axiosInstance.post<SignUpResponse>(`${authEndpointPrefix}/signup`, request);
  return res.data;
};

export const signOutUser = async (accessToken: string): Promise<void> => {
  await axiosInstance.post<void>(`${authEndpointPrefix}/signout`, { accessToken });
};

export const sendConfirmationCode = async (email: string): Promise<void> => {
  await axiosInstance.post<void>(`${authEndpointPrefix}/confirmation-code`, { email });
};

export const forgotPassword = async (email: string): Promise<void> => {
  await axiosInstance.post<void>(`${authEndpointPrefix}/forgot-password`, { email });
};

export const confirmResetPassword = async (request: {
  email: string;
  confirmationCode: string;
  newPassword: string;
}): Promise<void> => {
  await axiosInstance.post<void>(`${authEndpointPrefix}/reset-password`, request);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { ROUTES } from '@src/layout/RoutePage';
import { useNavigate } from 'react-router';

export const Copyright = (props: any) => {
  const navigate = useNavigate();

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" onClick={() => navigate(ROUTES.home)} sx={{ cursor: 'pointer' }}>
        GuruHealth
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

import {
  AlertProps,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import { useMemberClinicQuery } from '@src/queries/ClinicQuery';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ClinicDTO, ClinicMemberStatus, PatchUserDTO } from '@src/common-utils/DataModels';
import { AddressType, PatchPreferredDeliveryAddress, UserInfo, isAuthError } from '@src/common-utils/AuthModels';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { usePatchUser } from '@src/queries/UserManagementQuery';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { ROUTES } from '@src/layout/RoutePage';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNotificationContext } from '@src/contexts/NotificationProvider';
import { getStoreAuthInfo, storeAuthInfo } from '@src/common-utils/StoreUtil';

const ClinicAddress = ({
  clinics,
  defaultValue,
  onChange,
  disabled,
}: {
  clinics: ClinicDTO[];
  defaultValue?: string;
  onChange: (clinicId: string, address: string) => void;
  disabled: boolean;
}) => {
  const matchingValue = defaultValue ? clinics.find((clinic) => defaultValue === clinic.id) || clinics[0] : clinics[0];

  const handleChange = (event: SelectChangeEvent) => {
    const clinicId = event.target.value;
    onChange(clinicId, clinics.find((clinic) => clinic.id === clinicId)!.address);
  };

  useEffect(() => {
    onChange(matchingValue.id, matchingValue.address);
  }, [matchingValue, onChange]);

  return (
    <Select
      value={matchingValue.id}
      displayEmpty={true}
      onChange={handleChange}
      disabled={disabled}
      sx={{ width: '100%' }}
    >
      {clinics.map((clinic) => (
        <MenuItem key={`clinic-add-${clinic.id}`} value={clinic.id} sx={{ whiteSpace: 'normal' }}>
          {clinic.address}
        </MenuItem>
      ))}
    </Select>
  );
};

const YourAddress = ({ address }: { address: string }) => {
  return <Typography variant="body1">{address}</Typography>;
};

const OtherAddress = forwardRef(
  ({ address, onChange, disabled }: { address: string; onChange: (value: string) => void; disabled: boolean }, ref) => {
    return (
      <TextField
        // label="Other"
        variant="outlined"
        multiline={true}
        rows={2}
        onChange={(e) => onChange(e.target.value)}
        sx={{ width: '100%' }}
        disabled={disabled}
        defaultValue={address}
        inputRef={ref}
      />
    );
  },
);

const AdressRadio = ({
  addressType,
  label,
  isPreferredAddressType,
  handleSetPreferredAddressType,
}: {
  addressType: AddressType;
  label: string;
  isPreferredAddressType: boolean;
  handleSetPreferredAddressType: (addressType: AddressType) => void;
}) => {
  return (
    <FormControlLabel
      value={addressType}
      control={<Radio />}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
            {label}
          </Typography>
          <IconButton onClick={() => handleSetPreferredAddressType(addressType)} sx={{ cursor: 'pointer' }}>
            {isPreferredAddressType && <StarIcon fontSize="medium" color="success" />}
            {!isPreferredAddressType && (
              <Tooltip title={'Set as preferred delivery address'} arrow>
                <StarBorderIcon fontSize="medium" color="primary" />
              </Tooltip>
            )}
          </IconButton>
        </Box>
      }
    />
  );
};

type DeliveryAddressDialogProps = {
  onClose: () => void;
  onCancel: () => void;
  onDeliveryAddressChange: (addressType: AddressType, address: string, clinicId?: string) => void;
  defaultDeliveryAddressType: AddressType;
  defaultDeliveryAddress: string;
  defaultDeliveryClinicId?: string;
  userInfo: UserInfo;
};

export const DeliveryAddressDialog = ({
  onClose,
  onCancel,
  onDeliveryAddressChange,
  defaultDeliveryAddress,
  defaultDeliveryClinicId,
  defaultDeliveryAddressType,
  userInfo,
}: DeliveryAddressDialogProps) => {
  const [selectedAddressType, setSelectedAddressType] = useState<AddressType>(defaultDeliveryAddressType);

  const defaultPPreferredDeliveryAddress = {
    addressType: userInfo.preferredDeliveryAddress.addressType,
    value:
      userInfo.preferredDeliveryAddress.addressType === 'CLINIC'
        ? userInfo.preferredDeliveryAddress.clinicId
        : userInfo.preferredDeliveryAddress.address,
  };

  const [preferredDeliveryAddress, setPreferredDeliveryAddress] = useState<PatchPreferredDeliveryAddress>(
    defaultPPreferredDeliveryAddress,
  );

  const [deliveryAddress, setDeliveryAddress] = useState(defaultDeliveryAddress);

  const [deliveryClinicAddress, setDeliveryClinicAddress] = useState(defaultDeliveryAddress);
  const [deliveryClinicId, setDeliveryClinicId] = useState(defaultDeliveryClinicId);
  const [deliveryOtherAddress, setDeliveryOtherAddress] = useState(defaultDeliveryAddress);

  const [clinics, setClinics] = useState<ClinicDTO[]>([]);
  const { data: queryClinics } = useMemberClinicQuery();

  const { logout, refreshUserInfo } = useUserContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { showMessage } = useNotificationContext();

  const showNotification = (message: string, variant: AlertProps['severity']) => {
    showMessage(message, {
      position: isSmall ? 'top-center' : undefined,
      direction: isSmall ? 'down' : undefined,
      variant,
    });
  };

  const { patchUser } = usePatchUser({
    onSuccess: async (dto: PatchUserDTO) => {
      const authInfo = getStoreAuthInfo();
      if (authInfo) {
        const userInfo = authInfo.userInfo;
        userInfo.preferredDeliveryAddress = dto.preferredDeliveryAddress?.addressType
          ? {
              addressType: dto.preferredDeliveryAddress.addressType,
              address:
                dto.preferredDeliveryAddress.addressType === 'CLINIC'
                  ? deliveryClinicAddress
                  : dto.preferredDeliveryAddress.value,
              clinicId: dto.preferredDeliveryAddress.addressType === 'CLINIC' ? deliveryClinicId : undefined,
            }
          : { addressType: 'YOUR_ADDRESS', address: userInfo.address || '' };

        storeAuthInfo(authInfo);
        refreshUserInfo();
      }
      hndleDeliveryAddressChange();
    },
    onError: (error: Error) => {
      showNotification(error.message, 'error');
      if (isAuthError(error)) {
        logout().finally(() => {
          navigate(ROUTES.signin);
        });
      }
    },
  });

  const hndleDeliveryAddressChange = useCallback(() => {
    if (selectedAddressType === 'CLINIC') {
      onDeliveryAddressChange(selectedAddressType, deliveryClinicAddress, deliveryClinicId);
    } else if (selectedAddressType === 'YOUR_ADDRESS') {
      onDeliveryAddressChange(selectedAddressType, userInfo.address!, undefined);
    } else {
      onDeliveryAddressChange(selectedAddressType, deliveryOtherAddress, undefined);
    }
  }, [
    onDeliveryAddressChange,
    selectedAddressType,
    deliveryClinicAddress,
    deliveryClinicId,
    userInfo.address,
    deliveryOtherAddress,
  ]);

  const handleSave = () => {
    if (
      preferredDeliveryAddress.addressType !== userInfo.preferredDeliveryAddress?.addressType ||
      preferredDeliveryAddress.value !== userInfo.preferredDeliveryAddress?.address
    ) {
      patchUser({
        userId: userInfo.id,
        patchUserDTO: {
          preferredDeliveryAddress,
        },
      });
    } else {
      hndleDeliveryAddressChange();
    }
  };

  const handlePreferredAddressTypeChange = (addressType: AddressType) => {
    setPreferredDeliveryAddress({
      addressType,
      value:
        addressType === 'CLINIC'
          ? deliveryClinicId
          : addressType === 'YOUR_ADDRESS'
          ? userInfo.address!
          : deliveryOtherAddress,
    });
    setSelectedAddressType(addressType);
  };

  const handleAddressTypeChange = (addressType: AddressType) => {
    setSelectedAddressType(addressType);
  };

  useEffect(() => {
    setDeliveryAddress(
      selectedAddressType === 'CLINIC'
        ? deliveryClinicAddress
        : selectedAddressType === 'YOUR_ADDRESS'
        ? userInfo.address!
        : deliveryOtherAddress,
    );
  }, [selectedAddressType, deliveryClinicAddress, userInfo.address, deliveryOtherAddress]);

  const dirty =
    deliveryAddress !== defaultDeliveryAddress ||
    selectedAddressType !== defaultDeliveryAddressType ||
    deliveryClinicId !== defaultDeliveryClinicId ||
    preferredDeliveryAddress.addressType !== defaultPPreferredDeliveryAddress.addressType ||
    preferredDeliveryAddress.value !== defaultPPreferredDeliveryAddress.value;

  const otherAddressInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (queryClinics && queryClinics.length > 0) {
      setClinics(
        queryClinics
          .filter((c) => c.status === ClinicMemberStatus.ACCEPTED)
          .sort((c1, c2) => {
            if (c1.isDefault) {
              return -1;
            }
            if (c2.isDefault) {
              return -1;
            }
            return c1.clinic.name.toLowerCase().localeCompare(c2.clinic.name.toLowerCase());
          })
          .map((m) => m.clinic),
      );
    }
  }, [queryClinics]);

  // console.log('deliveryAddress', deliveryAddress);
  // console.log('deliveryClinicId', deliveryClinicId);
  // console.log('selectedAddressType', selectedAddressType);

  return (
    <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%', display: ' flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <BusinessIcon />
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Delivery Address
          </Typography>
        </Box>
        <IconButton size="medium" onClick={() => onClose()} sx={{ postion: 'relative', left: 20 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">{deliveryAddress}</Typography>
      </Box>

      <FormControl sx={{ mb: 1 }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(_event, value) => {
            handleAddressTypeChange(value as AddressType);
          }}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          value={selectedAddressType}
        >
          {clinics.length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <AdressRadio
                addressType="CLINIC"
                label="Clinic:"
                isPreferredAddressType={'CLINIC' === preferredDeliveryAddress.addressType}
                handleSetPreferredAddressType={handlePreferredAddressTypeChange}
              />
              <ClinicAddress
                clinics={clinics}
                defaultValue={deliveryClinicId || ''}
                onChange={(clinicId: string, address: string) => {
                  setDeliveryClinicAddress(address);
                  setDeliveryClinicId(clinicId);
                }}
                disabled={selectedAddressType !== 'CLINIC'}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <AdressRadio
              addressType="YOUR_ADDRESS"
              label="Your Address:"
              isPreferredAddressType={'YOUR_ADDRESS' === preferredDeliveryAddress.addressType}
              handleSetPreferredAddressType={handlePreferredAddressTypeChange}
            />
            <YourAddress address={userInfo.address || ''} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <AdressRadio
              addressType="OTHER"
              label="Other:"
              isPreferredAddressType={'OTHER' === preferredDeliveryAddress.addressType}
              handleSetPreferredAddressType={handlePreferredAddressTypeChange}
            />
            <OtherAddress
              address={deliveryOtherAddress}
              onChange={setDeliveryOtherAddress}
              disabled={selectedAddressType !== 'OTHER'}
              ref={otherAddressInputRef}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 1 }}>
        <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          sx={{ textTransform: 'none' }}
          onClick={handleSave}
          disabled={!dirty || !deliveryAddress}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import envConfig from '@src/env';
import {
  TOKEN_EXPIRATION_MARGIN_SECONDS,
  TOKEN_TTL,
  clearLocalStore,
  getStoreAuthInfo,
  getLastAccess,
  touchLastAccess,
} from '@src/common-utils/StoreUtil';
import { getEpochTime } from '@src/common-utils/Util';
import { refreshTokenAuth } from '@src/queries/RefreshTokenQuery';
import { ServerError } from '@src/common-utils/AuthModels';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create({
  baseURL: envConfig.apiEndpoint, // Your API base URL
  timeout: 30000, // Request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosRetry(axiosInstance, {
  retries: 2, // Number of retries
  // retryDelay: axiosRetry.exponentialDelay, // Exponential back-off delay
  retryDelay: () => 100,
  retryCondition: (error) => isNetworkError(error) || error.response?.status === 502,
});

// Helper function to check if the error is CORS-related/Network error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNetworkError = (error: any) => {
  return (
    error.request?.status === 0 && error.request?.readyState === 4
    // error.config &&
    // error.config.method.toUpperCase() === 'OPTIONS' // Check if it's a preflight (OPTIONS) request
  );
};

export const authEndpointPrefix = '/v1/auth';

export const productsEndpoint = '/v1/products';

export const ordersEndpoint = '/v1/orders';

export const userEndpointPrefix = '/v1/users';

export const clinicsEndpoint = '/v1/clinics';

export const membersEndpoint = '/v1/members';

const securedEndpoints = [ordersEndpoint, userEndpointPrefix, clinicsEndpoint, membersEndpoint];
const isSecuredRequest = (endpoint?: string): boolean =>
  securedEndpoints.some((securedEndpoint) => endpoint && endpoint.startsWith(securedEndpoint));

axiosInstance.interceptors.request.use(async (config) => {
  if (config.method?.toUpperCase() === 'POST') {
    config.headers['Content-Type'] = 'application/json';
  }

  const url = config.url;

  if (isSecuredRequest(url)) {
    touchLastAccess();
  }

  const authInfo = getStoreAuthInfo();

  const needAuthToken = isSecuredRequest(url) || url?.startsWith(productsEndpoint);

  // set auth token for secured endpoint, or products endpoint if any
  if (authInfo && needAuthToken) {
    // check if token expires
    const tokenExpiration = authInfo.tokenExpiration;
    const currentTime = getEpochTime();
    if (currentTime >= tokenExpiration - TOKEN_EXPIRATION_MARGIN_SECONDS) {
      // Token expires, check last access
      const lastAccess = getLastAccess();
      // has been touched within last TOKEN_TTL
      if (lastAccess && currentTime < lastAccess + TOKEN_TTL) {
        try {
          await refreshTokenAuth();
          config.headers['Authorization'] = `Bearer ${getStoreAuthInfo()?.idToken}`;
        } catch (e) {
          clearLocalStore();
          if (isSecuredRequest(url)) {
            const error: AxiosError = new AxiosError(
              `Failed to refresh token ${e}`,
              '401',
              config,
              {},
              {
                config,
                data: { error: { message: 'Failed to refresh token' } },
                status: 401,
                statusText: `Failed to refresh token ${e}`,
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            );
            throw error;
          }
        }
      }
    } else {
      config.headers['Authorization'] = `Bearer ${authInfo.idToken}`;
    }
  }

  return config;
});

type ErrorResponse = {
  errors?: { detail: string }[];
  error?: { message: string };
  message?: string;
};

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // const requestUrl = response.config.url;
    // if (isSecuredRequest(requestUrl)) {
    //   touchLastAccess();
    // }

    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.data) {
        const responseErrors = error.response.data as ErrorResponse;
        if (responseErrors.errors && responseErrors.errors.length > 0) {
          const errorDetails = responseErrors.errors.map((error) => error.detail).join(', ');
          // return Promise.reject(new ServerError(errorDetails, error.response.status));
          throw new ServerError(errorDetails, error.response.status);
        }

        if (responseErrors.message) {
          throw new ServerError(responseErrors.message, error.response.status);
        }

        if (responseErrors.error) {
          throw new ServerError(responseErrors.error.message, error.response.status);
        }
      }
      throw new ServerError('Unexpected error', error.response.status);
    } else if (error.request) {
      // no response, likely cors or 401 from cognito
      throw new ServerError('Unauthorized', HttpStatusCode.Unauthorized);
    } else {
      throw new ServerError('Request error', 0);
    }
  },
);

export default axiosInstance;

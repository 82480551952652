import { Box, CircularProgress, Container, Grid, MenuItem, Select, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { usePatchUser, useUserSearchQuery } from '@src/queries/UserManagementQuery';
import { useSearchContext } from '@src/contexts/SearchContextProvider';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { CognitoGroup, UserInfo, isAuthError } from '@src/common-utils/AuthModels';
import { Nullable } from '@src/common-utils/Models';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { ROUTES } from '@src/layout/RoutePage';
import { useNavigate } from 'react-router-dom';

const priceLevels = ['A', 'B', 'C', 'D', 'E'];
const cognitoGroups = (loginUser: UserInfo) =>
  loginUser.groups?.includes(CognitoGroup.SystemAdmin)
    ? Object.values(CognitoGroup)
    : [CognitoGroup.Customer, CognitoGroup.User, CognitoGroup.Admin];

type PatchUserFormData = {
  priceLevel: string;
  groups?: CognitoGroup[];
};

const schema = yup.object({
  priceLevel: yup.string().required('Price Level is required'),
});

export const UserManagement = () => {
  const navigate = useNavigate();

  const { userSearchCriteria, setIsLoading } = useSearchContext();

  const { userInfo: loginUser, logout } = useUserContext();

  const [user, setUser] = useState<Nullable<UserInfo>>(null);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [successMessage, setSuccessMessage] = useState('');

  const {
    data: users,
    refetch,
    isLoading,
    isFetching,
  } = useUserSearchQuery({
    email: userSearchCriteria?.email,
    onError: (error: Error) => {
      setError(error);
      if (isAuthError(error)) {
        logout().finally(() => {
          navigate(ROUTES.signin);
        });
      }
    },
  });

  const { patchUser } = usePatchUser({
    onSuccess: async () => {
      setSuccessMessage('User was updated successfully');
    },
    onError: (error: Error) => {
      setError(error);
      if (isAuthError(error)) {
        logout().finally(() => {
          navigate(ROUTES.signin);
        });
      }
    },
  });

  useEffect(() => {
    if (userSearchCriteria && userSearchCriteria.email) {
      refetch();
    }
  }, [userSearchCriteria, refetch]);

  useEffect(() => setIsLoading(isLoading || isFetching), [isLoading, isFetching, setIsLoading]);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting, isValid, dirtyFields },
    setValue,
    // reset,
  } = useForm<PatchUserFormData>({
    defaultValues: {
      priceLevel: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: PatchUserFormData) => {
    if (user) {
      setSuccessMessage('');
      setError(null);
      patchUser({
        userId: user.id,
        patchUserDTO: {
          ...data,
        },
      });
    }
  };

  useEffect(() => {
    if (users && users.length > 0) {
      setUser(users[0]);
      setValue('priceLevel', users[0].priceLevel || '');
      setValue('groups', users[0].groups || []);
    } else setUser(null);
  }, [users, setValue]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <GroupIcon />
          <Typography component="h1" variant="h5">
            Customer Management
          </Typography>
        </Box>
        {!user && !userSearchCriteria && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, marginTop: 1 }}>
            <Typography component="h6" variant="subtitle1">
              Please provide user's email and perform search
            </Typography>
          </Box>
        )}
        {!user && userSearchCriteria && !isLoading && !isFetching && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, marginTop: 1 }}>
            <Typography component="h6" variant="subtitle1">
              No customer/user matching the search email
            </Typography>
          </Box>
        )}
        {user && (
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Username:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                    {user.firstName} {user.lastName}
                  </Typography>
                </Box>
              </Grid>
              {/* email */}
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Email:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                    {user.email}
                  </Typography>
                </Box>
              </Grid>
              {/* phone */}
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Phone:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                    {user.phoneNumber}
                  </Typography>
                </Box>
              </Grid>
              {/* address */}
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Address:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                    {user.address}
                  </Typography>
                </Box>
              </Grid>
              {/* priceLevel */}
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Price Level:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="priceLevel"
                  control={control}
                  defaultValue={user?.priceLevel}
                  render={({ field }) => (
                    <Select
                      {...field}
                      // value={user.priceLevel}
                      label=""
                      size="small"
                      displayEmpty={true}
                      error={Boolean(errors.priceLevel)}
                      // onChange={(e) => setValue('priceLevel', e.target.value as string)}
                      // helperText={errors.priceLevel?.message || ''}
                    >
                      {priceLevels.map((level) => (
                        <MenuItem key={`price-level-${level}`} value={level}>
                          {level}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              {/* groups */}
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Roles:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="groups"
                  control={control}
                  defaultValue={user?.groups || []}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label=""
                      size="small"
                      displayEmpty={true}
                      error={Boolean(errors.groups)}
                      multiple={true}
                    >
                      {loginUser &&
                        cognitoGroups(loginUser).map((group) => (
                          <MenuItem key={`cognito-group-${group}`} value={group}>
                            {group}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography component="h4" variant="body2" color="error">
                    {error.message}
                  </Typography>
                </Grid>
              )}
              {successMessage && (
                <Grid item xs={12}>
                  <Typography component="h4" variant="body2" color="success" sx={{ color: 'blue' }}>
                    {successMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <LoadingButton
              loading={isSubmitting}
              loadingPosition="end"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isDirty || isSubmitting || !isValid || Object.keys(dirtyFields).length === 0}
              disableElevation
              endIcon={<CircularProgress size={isSubmitting ? 20 : 0} />}
            >
              Submit
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Container>
  );
};

import '@src/App.scss';
import { LandingPage } from '@src/layout/LandingPage';
import { AppContextProvider } from '@src/contexts/AppContextProvider';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme

const App = () => {
  return (
    <AppContextProvider>
      <LandingPage />
    </AppContextProvider>
  );
};

export default App;

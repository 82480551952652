import { Nullable } from '@src/common-utils/Models';
import { UserSearchCriteria } from '@src/queries/UserManagementQuery';
import { ReactNode, createContext, useContext, useState } from 'react';

type SearchContextType = {
  // isLoading: boolean;
  // isFetching: boolean;
  // isFetchingNextPage: boolean;
  // products: Product[];
  // search: (searchName: string) => void;
  // resetSearch: () => void;
  // hasNextPage?: boolean;
  // fetchNextPage: () => void;
  // refetch: () => void;
  productSearchName?: Nullable<string>;
  setProductSearchName: (searchName: Nullable<string>) => void;
  userSearchCriteria?: Nullable<UserSearchCriteria>;
  setUserSearchCriteria: (searchCriteria: Nullable<UserSearchCriteria>) => void;
  isLoading?: boolean;
  setIsLoading: (loading?: boolean) => void;
};

export const SearchContext = createContext<SearchContextType>({
  productSearchName: '',
  setProductSearchName: () => {
    //no ops
  },
  userSearchCriteria: null,
  setUserSearchCriteria: () => {
    //no ops
  },
  isLoading: false,
  setIsLoading: () => {
    //no ops
  },
});

export const SearchContextProvider = ({ children }: { children: ReactNode }) => {
  const [productSearchName, setProductSearchName] = useState<Nullable<string>>(null);

  const [userSearchCriteria, setUserSearchCriteria] = useState<Nullable<UserSearchCriteria>>(null);

  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

  return (
    <SearchContext.Provider
      value={{
        productSearchName,
        setProductSearchName,
        userSearchCriteria,
        setUserSearchCriteria,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);

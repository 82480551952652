import { useMediaQuery, useTheme } from '@mui/material';
import { useClinicQuery, useDeleteClinicQuery } from '@src/queries/ClinicQuery';
import { ClinicManagementMobile } from '@src/components/clinic/clinic-management/ClinicManagementMobile';
import { ClinicManagementGrid } from '@src/components/clinic/clinic-management/ClinicManagementGrid';
import { useNotificationContext } from '@src/contexts/NotificationProvider';
import { useState } from 'react';
import { ClinicDialog, ClinicDialogProps } from '@src/components/clinic/clinic-management/ClinicDialog';
import { ClinicDTO, ClinicMemberDTO } from '@src/common-utils/DataModels';
import { Nullable } from '@src/common-utils/Models';
import { useConfirm } from 'material-ui-confirm';
import { InviteClinicMemberDialog } from '@src/components/clinic/clinic-management/InviteClinicMemberDialog';
import { ClinicMenItem } from '@src/components/clinic/clinic-management/menu/ClinicMenu';
import { ClinicMembersDialog } from '@src/components/clinic/clinic-management/ClinicMembersDialog';

export const ClinicManagement = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { data } = useClinicQuery();

  const { showMessage } = useNotificationContext();
  const [mode, setMode] = useState<ClinicDialogProps['mode']>('CREATE');
  const [selectedClinic, setSelectedClinic] = useState<Nullable<ClinicDTO>>(null);

  const [clinicAddEditOpen, setClinicAddEditOpen] = useState(false);
  const [inviteMemberOpen, setInviteMemberOpen] = useState(false);
  const [clinicMemberOpen, setClinicMemberOpen] = useState(false);

  const confirm = useConfirm();

  const showNotification = (message: string, isSuccess = true) => {
    showMessage(message, {
      position: isSmall ? 'top-center' : undefined,
      direction: isSmall ? 'down' : undefined,
      variant: isSuccess ? 'success' : 'error',
    });
  };

  const { deleteClinic } = useDeleteClinicQuery({
    onSuccess: async () => showNotification('Clinic was deleted successfully'),
    onError: (error: Error) => showNotification(error.message, false),
  });

  const handleAddClinic = () => {
    setSelectedClinic(null);
    setMode('CREATE');
    setClinicAddEditOpen(true);
    setInviteMemberOpen(false);
  };

  const handleSelectClinicMenu = (action: ClinicMenItem, clinic: ClinicDTO) => {
    switch (action) {
      case 'edit-clinic':
        setMode('UPDATE');
        setSelectedClinic(clinic);
        setClinicAddEditOpen(true);
        setInviteMemberOpen(false);
        setClinicMemberOpen(false);
        break;
      case 'invite-member':
        setSelectedClinic(clinic);
        setClinicAddEditOpen(false);
        setInviteMemberOpen(true);
        setClinicMemberOpen(false);
        break;
      case 'delete-clinic':
        confirm({ title: 'Delete Clinic', content: 'Are you sure you want to delete this clinic?' })
          .then(() => {
            deleteClinic(clinic.id);
          })
          .catch(() => {
            // np op
          });
        break;
      case 'clinic-members':
        setSelectedClinic(clinic);
        setClinicAddEditOpen(false);
        setInviteMemberOpen(false);
        setClinicMemberOpen(true);
        break;
      default:
    }
  };

  const handleAddUpdateSuccess = () => {
    setClinicAddEditOpen(false);
    showNotification(`Clinic was ${mode === 'CREATE' ? 'added' : 'updated'} successfully`);
  };

  const handleInvitememberSuccess = (data: ClinicMemberDTO) => {
    setInviteMemberOpen(false);
    showNotification(`The invite was sent to ${data.member.email} successfully`);
  };

  return (
    <>
      {!isSmall && (
        <ClinicManagementGrid
          data={data}
          onAddClinic={handleAddClinic}
          handleSelectClinicMenu={handleSelectClinicMenu}
        />
      )}
      {isSmall && (
        <ClinicManagementMobile
          data={data}
          onAddClinic={handleAddClinic}
          handleSelectClinicMenu={handleSelectClinicMenu}
        />
      )}
      {clinicAddEditOpen && (
        <ClinicDialog
          mode={mode}
          open={clinicAddEditOpen}
          onClose={() => setClinicAddEditOpen(false)}
          onSuccess={handleAddUpdateSuccess}
          initialValue={selectedClinic}
        />
      )}
      {inviteMemberOpen && (
        <InviteClinicMemberDialog
          clinicId={selectedClinic?.id || ''}
          open={inviteMemberOpen}
          onClose={() => setInviteMemberOpen(false)}
          onSuccess={handleInvitememberSuccess}
        />
      )}
      {clinicMemberOpen && (
        <ClinicMembersDialog
          clinic={selectedClinic!}
          open={clinicMemberOpen}
          onClose={() => setClinicMemberOpen(false)}
        />
      )}
    </>
  );
};

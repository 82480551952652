import { Nullable } from '@src/common-utils/Models';
import { HttpStatusCode } from 'axios';

export enum CognitoGroup {
  SystemAdmin = 'SystemAdmin',
  Admin = 'Admin',
  User = 'User',
  Customer = 'Customer',
}

export const adminGroups = [CognitoGroup.Admin, CognitoGroup.SystemAdmin];

export const isAdminRole = (group: CognitoGroup) => adminGroups.includes(group);
export const isSystemAdminRole = (group: CognitoGroup) => CognitoGroup.SystemAdmin === group;

export enum AuthStatus {
  TOKEN_VALID = 'TOKEN_VALID',
  LAST_ACCESS_VALID = 'LAST_ACCESS_VALID',
  EXPIRED = 'EXPIRED',
}

export type LoginData = {
  email: string;
  password: string;
};

export type AddressType = 'CLINIC' | 'YOUR_ADDRESS' | 'OTHER';

export type PatchPreferredDeliveryAddress = {
  addressType: AddressType;
  value?: string;
};

export type PreferredDeliveryAddressDTO = {
  addressType: AddressType;
  address?: string;
  clinicId?: string;
};

export type UserInfo = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  address?: Nullable<string>;
  groups?: Nullable<CognitoGroup[]>;
  priceLevel?: string;
  preferredDeliveryAddress: PreferredDeliveryAddressDTO;
};

export type AuthInfo = {
  idToken: string;
  accessToken: string;
  userInfo: UserInfo;
  tokenExpiration: number;
};

export type SignUpRequest = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address?: Nullable<string>;
};

export type SignUpResponse = {
  userConfirmed?: boolean;
  userId?: string;
};

export class ServerError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.name = 'ServerError';
    this.statusCode = statusCode;
  }
}

export const isAuthError = (e: unknown): boolean =>
  e instanceof ServerError && e.statusCode === HttpStatusCode.Unauthorized;

import { Nullable, QueryResult } from '@src/common-utils/Models';
import { ProductDTO } from '@src/common-utils/DataModels';
import axiosInstance, { productsEndpoint } from '@src/libs/axios-util';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { useCallback, useRef } from 'react';

export type ProductsQueryResponse = {
  products: ProductDTO[];
  lastEvaluatedKey?: Nullable<string>;
};

export const productsQueryKey = ['products'];
export const productsInfiniteQueryKey = ['products-infinite'];

export const fetchProducts = async ({
  productCodes,
  onError,
}: {
  productCodes?: string[];
  onError?: (error: Error) => void;
}): Promise<ProductDTO[] | undefined> => {
  try {
    const res = await axiosInstance.get<ProductsQueryResponse>(
      productCodes && productCodes.length > 0
        ? `${productsEndpoint}?productCodes=${encodeURIComponent(productCodes.join(','))}`
        : productsEndpoint,
    );
    return res.data?.products;
  } catch (e) {
    if (onError) {
      onError(e instanceof Error ? e : Error('Unexpected error'));
    }

    return undefined;
  }
};

export const buildProductCodesQueryKey = (codes: string[]) => {
  const sortedCodes = [...new Set(codes)].sort((code1, code2) =>
    code1.toLowerCase().localeCompare(code2.toLowerCase()),
  );
  return [...productsQueryKey, ...sortedCodes];
};

export const useProductsQuery = ({ lazy = false }: { lazy?: boolean } = {}): QueryResult<ProductDTO[]> & {
  dynamicRefetch: (newProductIds: string[]) => Promise<QueryObserverResult<ProductDTO[], Error>>;
} => {
  const productCodesRef = useRef<string[]>([]);
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery<ProductDTO[], Error>(
    [...productCodesRef.current],
    async () => {
      const res = await axiosInstance.get<ProductsQueryResponse>(
        productCodesRef.current.length > 0
          ? `${productsEndpoint}?productCodes=${encodeURIComponent(productCodesRef.current.join(','))}`
          : productsEndpoint,
      );
      return res.data?.products;
    },
    {
      enabled: !lazy,
      // staleTime: lazy ? Infinity : undefined,
    },
  );

  const dynamicRefetch = useCallback(
    (newProductCodes: string[]) => {
      productCodesRef.current = buildProductCodesQueryKey(newProductCodes);
      return refetch();
    },
    [refetch],
  );

  return {
    data: data || [],
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
    dynamicRefetch,
  };
};

export const useProductQuery = ({
  productCode,
  lazy = false,
}: {
  productCode: string;
  lazy?: boolean;
}): QueryResult<ProductDTO> => {
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery<ProductDTO, Error>(
    [...productsQueryKey, productCode],
    async () => {
      const res = await axiosInstance.get<ProductDTO>(`${productsEndpoint}/${productCode}`);
      return res.data;
    },
    {
      enabled: !lazy,
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
  };
};

// infinite
export const defaultPageSize = 48;

type ProductSearchPros = {
  searchName?: Nullable<string>;
  pageSize?: number;
};

type InfiniteProductsQueryResult = {
  isLoading: boolean;
  status: string;
  data?: InfiniteData<ProductsQueryResponse>;
  error: Nullable<Error>;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<ProductsQueryResponse, Error>>;
  hasNextPage?: boolean;
  refetch: () => void;
  resetQuery: () => void;
};

export const useInfiniteProductsQuery = ({
  searchName,
  pageSize = defaultPageSize,
}: ProductSearchPros): InfiniteProductsQueryResult => {
  const {
    isLoading,
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    // isFetchingPreviousPage,
    fetchNextPage,
    // fetchPreviousPage,
    hasNextPage,
    // hasPreviousPage,
    refetch,
    remove,
  } = useInfiniteQuery<ProductsQueryResponse, Error>(
    searchName ? [...productsInfiniteQueryKey, searchName] : productsInfiniteQueryKey,
    // productsInfiniteQueryKey,
    async ({ pageParam = undefined }) => {
      const params = new URLSearchParams([['pageSize', String(pageSize)]]);
      if (searchName) {
        params.set('searchName', searchName);
      }

      if (pageParam) {
        params.set('exclusiveStartKey', pageParam);
      }

      const res = await axiosInstance.get<ProductsQueryResponse>(productsEndpoint, { params });
      return res.data;
    },
    {
      // getPreviousPageParam: (firstPage) => firstPage.lastEvaluatedKey ?? undefined,
      getNextPageParam: (lastPage) => lastPage.lastEvaluatedKey || undefined,
      enabled: false,
      retry: 1,
    },
  );

  return {
    status,
    isLoading,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    resetQuery: () => {
      remove();
    },
  };
};

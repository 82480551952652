import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Copyright } from '@src/components/common/Copyright';
import MailIcon from '@mui/icons-material/Mail';

const openEmail = (email: string) => (window.location.href = `mailto:${email}`);

export const Footer = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        p: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flexDirection: !isSmall ? 'row' : 'column',
      }}
      component="footer"
    >
      <Copyright />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => openEmail('contact@dentalware.co.nz')}
      >
        <MailIcon sx={{ mr: '4px' }} color="info" />
        <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
          orders@dentalware.co.nz
        </Typography>
      </Box>
    </Box>
  );
};

import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { AddClinicMemberDTO, ClinicMemberDTO } from '@src/common-utils/DataModels';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Nullable } from '@src/common-utils/Models';
import { useEffect, useState } from 'react';
import { useAddClinicMemberQuery } from '@src/queries/ClinicQuery';

type InvaiteClinicMemberDialogProps = {
  clinicId: string;
  onClose: () => void;
  open: boolean;
  onSuccess?: (data: ClinicMemberDTO) => void;
  onError?: (error: Error) => void;
};

const schema = yup.object({
  memberEmail: yup.string().email('Invalid email format').required('Required'),
});

export const InviteClinicMemberDialog = ({
  onClose,
  open,
  clinicId,
  onSuccess,
  onError,
}: InvaiteClinicMemberDialogProps) => {
  const handleClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick') {
      doClose();
    }
  };

  const { addClinicMember } = useAddClinicMemberQuery({
    onError: (error: Error) => {
      setError(error);
      if (onError) {
        onError(error);
      }
    },
    onSuccess: async (data: ClinicMemberDTO) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  const [error, setError] = useState<Nullable<Error>>(null);

  const onSubmit = async (data: AddClinicMemberDTO) => {
    addClinicMember({ clinicId, memberEmail: data.memberEmail });
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting, isValid, dirtyFields },
    // setValue,
    reset,
  } = useForm<AddClinicMemberDTO>({
    defaultValues: {
      memberEmail: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      setError(null);
      reset();
    };
  }, [reset]);

  const doClose = () => {
    setError(null);
    reset();
    onClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} aria-labelledby="responsive-dialog-title" onClose={handleClose}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Box sx={{ width: '100%', display: ' flex', justifyContent: 'space-between', alignItems: 'top' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
              <PersonAddIcon />
              <Typography variant="h6">Invite Members</Typography>
            </Box>
            <IconButton size="medium" onClick={doClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3, width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="memberEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="memberEmail"
                      label="Email"
                      error={Boolean(errors.memberEmail)}
                      helperText={errors.memberEmail?.message || ''}
                    />
                  )}
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography component="h4" variant="body2" color="error">
                    {error.message}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <LoadingButton
              loading={isSubmitting}
              loadingPosition="end"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none' }}
              disabled={!isDirty || isSubmitting || !isValid || Object.keys(dirtyFields).length === 0}
              disableElevation
              endIcon={<CircularProgress size={isSubmitting ? 20 : 0} />}
            >
              Send Invite
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

import { Nullable } from '@src/common-utils/Models';
import { CognitoGroup, PatchPreferredDeliveryAddress, UserInfo } from '@src/common-utils/AuthModels';

export type ImageDTO = {
  name: string;
  url: string;
};

export type DiscountType = 'BUY_X_GET_Y_FREE' | 'PERCENTAGE';

export type ProductDiscount = {
  description: string;
  discountType: DiscountType;
  discountValue: number;
  discountYValue?: Nullable<number>;
};

export type ProductDTO = {
  code: string;
  name: string;
  description?: Nullable<string>;
  specification: string[];
  packing: string;
  unit: string;
  price: number;
  images: ImageDTO[];
  discount?: Nullable<ProductDiscount>;
};

export type LocalStoreCartItem = {
  productCode: string;
  quantity: number;
};

export type CartItem = LocalStoreCartItem & {
  name: string;
  packing: string;
  unit: string;
  price: number;
  itemTotals: number;
  imageURL?: string;
  discount?: Nullable<ProductDiscount>;
};

export type DiscountCartItem = {
  productCode: string;
  productName: string;
  discountAmount: number;
  description: string;
};

export type MyCart = {
  userId: string;
  userEmail: string;
  cartItems?: Nullable<CartItem[]>;
};

export type OrderItem = {
  productCode: string;
  quantity: number;
  // price: number;
};

export type CreateOrderDTO = {
  deliveryAddress: string;
  deliveryClinicId?: Nullable<string>;
  orderItems: OrderItem[];
};

export type CreateOrderResponse = {
  id: string;
  userId: string;
  userEmail: string;
  status: string;
  totals: number;
};

export type UserSearchResponse = {
  users: UserInfo[];
  lastEvaluatedKey?: Nullable<string>;
};

export type PatchUserDTO = {
  priceLevel?: string;
  groups?: CognitoGroup[];
  preferredDeliveryAddress?: Nullable<PatchPreferredDeliveryAddress>;
};

export type ClinicDTO = {
  id: string;
  name: string;
  phoneNumber: string;
  address: string;
  owner: string;
};

export type GetClinicsResponse = {
  clinics: ClinicDTO[];
};

export type CreateClinicDTO = {
  name: string;
  phoneNumber: string;
  address: string;
  addOwnerAsMember?: boolean;
};

export type AddClinicMemberDTO = {
  memberEmail: string;
};

export enum ClinicMemberStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type ClinicMemberDTO = {
  clinicId: string;
  member: {
    id: string;
    email: string;
  };
  status: ClinicMemberStatus;
};

export type GetClinicMembersResponse = {
  clinicMembers: ClinicMemberDTO[];
};

export type ClinicMemberIdentifier = {
  clinicId: string;
  memberId: string;
};

export type MemberClinicDTO = {
  memberId: string;
  clinic: ClinicDTO;
  status: ClinicMemberStatus;
  isDefault?: boolean;
};

export type PatchMemberClinicDTO = {
  memberId: string;
  clinicId: string;
  status?: ClinicMemberStatus;
  isDefault?: boolean;
};

export type GetMemberClinicsResponse = {
  memberClinics: MemberClinicDTO[];
};

export const StatusDisplay: Record<ClinicMemberStatus, string> = {
  [ClinicMemberStatus.PENDING]: 'Pending',
  [ClinicMemberStatus.ACCEPTED]: 'Accepted',
  [ClinicMemberStatus.DECLINED]: 'Declined',
};

export type DeliveryAddress = {
  addressType: string;
  address: string;
  clinicId?: Nullable<string>;
};

import { UserInfo } from '@src/common-utils/AuthModels';
import { PatchUserDTO, UserSearchResponse } from '@src/common-utils/DataModels';
import { Nullable, QueryResult } from '@src/common-utils/Models';
import axiosInstance, { userEndpointPrefix } from '@src/libs/axios-util';
import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';

export type UserSearchCriteria = {
  email?: Nullable<string>;
};

export const useUserSearchQuery = ({
  email,
  onError,
  onSuccess,
}: UserSearchCriteria & { onError?: (e: Error) => void; onSuccess?: () => void }): QueryResult<UserInfo[]> => {
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery<UserInfo[], Error>(
    ['user-search', email],
    async () => {
      const res = await axiosInstance.get<UserSearchResponse>(userEndpointPrefix, { params: { email } });
      return (res.data as UserSearchResponse).users;
    },
    {
      retry: 1,
      enabled: false,
      onError,
      onSuccess,
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
  };
};

type UsePatchUserProps = {
  onSuccess: (patchUserDTO: PatchUserDTO) => Promise<void>;
  onError?: (error: Error) => void;
};

const performPatchUser = async ({
  userId,
  patchUserDTO,
}: {
  userId: string;
  patchUserDTO: PatchUserDTO;
}): Promise<PatchUserDTO> => {
  await axiosInstance({ url: `${userEndpointPrefix}/${userId}`, method: 'PATCH', data: patchUserDTO });
  return patchUserDTO;
};

export const usePatchUser = ({
  onSuccess,
  onError,
}: UsePatchUserProps): {
  patchUser: UseMutateFunction<PatchUserDTO, Error, { userId: string; patchUserDTO: PatchUserDTO }, unknown>;
  isLoading: boolean;
} => {
  const { mutate: patchUser, isLoading } = useMutation(performPatchUser, {
    onSuccess: (data) => onSuccess(data),
    onError,
  });

  return { patchUser, isLoading };
};

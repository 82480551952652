import { Cart } from '@src/components/cart/Cart';
import { Header } from '@src/components/header/Header';
import { Footer } from '@src/footer/Footer';
import { useState } from 'react';
import { RoutePage } from '@src/layout/RoutePage';
import { withAuth } from '@src/components/common/ProtectedPage';
import { Box } from '@mui/material';
import { useUserContext } from '@src/contexts/UserContextProvider';

const ProtectedCart = withAuth(Cart);

export const LandingPage = () => {
  const [cartOpen, setCartOpen] = useState(false);
  const onCartClick = () => setCartOpen((preOpen) => !preOpen);
  const { userInfo } = useUserContext();

  return (
    <>
      {userInfo && (
        <ProtectedCart open={cartOpen} onOpen={() => setCartOpen(true)} onClose={() => setCartOpen(false)} />
      )}
      <Header onCartClick={onCartClick} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space',
          width: '100%',
          height: '100vh',
          pt: '72px',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space',
            width: '100%',
            flexGrow: 1,
            // mt: '56px',
            // top: '56px',
          }}
        >
          <RoutePage />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

import { useMediaQuery, useTheme } from '@mui/material';
import { MyClinicGrid } from '@src/components/clinic/my-clinic/MyClinicGrid';
import { useNotificationContext } from '@src/contexts/NotificationProvider';
import { useMemberClinicQuery, useUpdateMemberClinicQuery } from '@src/queries/ClinicQuery';
import { MyClinicMobile } from '@src/components/clinic/my-clinic/MyClinicMobile';
import { ClinicMemberStatus, MemberClinicDTO, PatchMemberClinicDTO } from '@src/common-utils/DataModels';
import { AlertProps } from '@mui/material/Alert';
import { useState } from 'react';
import { Nullable } from '@src/common-utils/Models';

export const MyClinic = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { showMessage } = useNotificationContext();

  const showNotification = (message: string, variant: AlertProps['severity']) => {
    showMessage(message, {
      position: isSmall ? 'top-center' : undefined,
      direction: isSmall ? 'down' : undefined,
      variant,
    });
  };

  const { data, error } = useMemberClinicQuery({
    onError: (e) => {
      showNotification(e.message, 'error');
    },
  });

  const [updateAction, setUpdateAction] = useState<Nullable<'ChangeDefaultClinic' | 'MemberInvite'>>(null);
  const [currentUpdatedMember, setCurrentUpdatedMember] = useState<Nullable<MemberClinicDTO>>(null);

  const { updateMemberClinic } = useUpdateMemberClinicQuery({
    onSuccess: async (dto: PatchMemberClinicDTO) => {
      // const updatedMember = data?.find((member) => dto.clinicId === member.clinic.id);
      const message =
        updateAction === 'ChangeDefaultClinic'
          ? `The clinic ${
              dto.isDefault ? 'has been set as the default clinic.' : 'has been set as the non-default clinic.'
            }`
          : `Member invite was ${dto.status === ClinicMemberStatus.ACCEPTED ? 'accepted' : 'declined'}`;

      const variant =
        updateAction === 'ChangeDefaultClinic'
          ? 'success'
          : dto.status === ClinicMemberStatus.ACCEPTED
          ? 'success'
          : dto.status === ClinicMemberStatus.DECLINED
          ? 'warning'
          : 'error';

      setUpdateAction(null);
      setCurrentUpdatedMember(null);
      showNotification(message, variant);
    },

    onError: (e: Error) => {
      setUpdateAction(null);
      setCurrentUpdatedMember(null);
      showNotification(e.message, 'error');
    },
  });

  const handleSetDefaultClinic = (data: MemberClinicDTO) => {
    if (data.status === ClinicMemberStatus.ACCEPTED) {
      setUpdateAction('ChangeDefaultClinic');
      setCurrentUpdatedMember(data);
      updateMemberClinic({
        memberId: data.memberId,
        clinicId: data.clinic.id,
        isDefault: data.isDefault ? false : true,
      });
    }
  };

  const handleChangeStatus = (data: MemberClinicDTO, newStatus: ClinicMemberStatus) => {
    setUpdateAction('MemberInvite');
    setCurrentUpdatedMember(data);
    updateMemberClinic({ memberId: data.memberId, clinicId: data.clinic.id, status: newStatus });
  };

  return (
    <>
      {!isSmall && (
        <MyClinicGrid
          data={data || []}
          error={error}
          handleSetDefaultClinic={handleSetDefaultClinic}
          handleChangeStatus={handleChangeStatus}
        />
      )}
      {isSmall && (
        <MyClinicMobile
          data={data || []}
          error={error}
          handleSetDefaultClinic={handleSetDefaultClinic}
          updateAction={updateAction}
          currentUpdatedMember={currentUpdatedMember}
        />
      )}
    </>
  );
};

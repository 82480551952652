import { CreateOrderDTO, CreateOrderResponse } from '@src/common-utils/DataModels';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import axiosInstance, { ordersEndpoint } from '@src/libs/axios-util';

type UseCreateOrderProps = {
  onSuccess: (res: CreateOrderResponse) => Promise<void>;
  onError?: (error: Error) => void;
};

const performCreateOrder = async (createorderDTO: CreateOrderDTO): Promise<CreateOrderResponse> => {
  const resp = await axiosInstance({ url: ordersEndpoint, method: 'POST', data: createorderDTO });
  return resp.data;
};

export const useCreateOrder = ({
  onSuccess,
  onError,
}: UseCreateOrderProps): {
  createOrder: UseMutateFunction<CreateOrderResponse, Error, CreateOrderDTO, unknown>;
  isLoading: boolean;
} => {
  const { mutate: createOrder, isLoading } = useMutation(performCreateOrder, {
    onSuccess: (data) => onSuccess(data),
    onError,
    retry: 1,
    retryDelay: 200,
  });

  return { createOrder, isLoading };
};

import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Nullable } from '@src/common-utils/Models';
import { AuthInfo, AuthStatus, LoginData, SignUpRequest, UserInfo } from '@src/common-utils/AuthModels';
import { getEpochTime } from '@src/common-utils/Util';
// import  {
// signInUser,
// signOutUser,
// forgotPassword,
// confirmResetPassword,
// sendConfirmationCode} from '@src/libs/cognito-client';
import {
  signInUser,
  signUpUser,
  signOutUser,
  sendConfirmationCode,
  forgotPassword,
  confirmResetPassword,
} from '@src/queries/AuthQuery';
import {
  TOKEN_EXPIRATION_MARGIN_SECONDS,
  TOKEN_TTL,
  checkAuthenticated,
  clearAuthInfo,
  clearLocalStore,
  getStoreAuthInfo,
  getLastAccess,
  storeAuthInfo,
  touchLastAccess,
} from '@src/common-utils/StoreUtil';
// import { setRefreshTokenInCookie } from '@src/libs/refresh-token';

type ActionCallback = {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

type LoginProps = {
  loginData: LoginData;
} & ActionCallback;

type SignupProps = {
  signupData: SignUpRequest;
} & ActionCallback;

type SendResetPasswordCodeProps = {
  email: string;
} & ActionCallback;

type ResetPasswordProps = {
  email: string;
  newPassword: string;
  confirmationCode: string;
} & ActionCallback;

type ResendConfirmationCodeProps = {
  email: string;
} & ActionCallback;

type UserContextType = {
  isLoading?: boolean;
  userInfo: Nullable<UserInfo>;
  login: (props: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  signUp: (props: SignupProps) => Promise<void>;
  sendResetPasswordCode: (props: SendResetPasswordCodeProps) => Promise<void>;
  resetPassword: (props: ResetPasswordProps) => Promise<void>;
  resendConfirmationCode: (props: ResendConfirmationCodeProps) => Promise<void>;
  getAuthStatus: () => AuthStatus;
  refreshUserInfo: () => void;
};

export const UserContext = createContext<UserContextType>({
  isLoading: false,
  userInfo: null,
  login: async () => {
    //no op
  },
  logout: async () => {
    // no op
  },
  signUp: async () => {
    //no op
  },
  sendResetPasswordCode: async () => {
    //no op
  },
  resetPassword: async () => {
    //no op
  },
  resendConfirmationCode: async () => {
    //no op
  },
  getAuthStatus: () => AuthStatus.EXPIRED,

  refreshUserInfo: async () => {
    //no op
  },
});

// const doLogin = async ({ email, password }: LoginData): Promise<AuthInfo> => {
//   try {
//     const { authInfo, refreshToken } = await signInUser(email, password);
//     storeAuthInfo(authInfo);
//     await setRefreshTokenInCookie(refreshToken);

//     return authInfo;
//   } catch (e) {
//     clearLocalStore();
//     throw e;
//   }
// };

const doLogout = async () => {
  const authInfo = getStoreAuthInfo();
  if (!authInfo) {
    return;
  }

  try {
    await signOutUser(authInfo.accessToken);
  } catch (e) {
    // can ignore logout error
  }

  clearLocalStore();
};

const buildError = (e: unknown) => (e instanceof Error ? e : Error('Unexpected error'));

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<Nullable<UserInfo>>(null);

  useEffect(() => {
    const authInfo = getStoreAuthInfo();
    const lastAccess = getLastAccess();

    if (authInfo) {
      // check if there is auth in localStorage and verfy the token
      const currentEpochTime = getEpochTime();
      if (
        (lastAccess && currentEpochTime >= lastAccess + TOKEN_TTL) ||
        currentEpochTime >= authInfo.tokenExpiration - TOKEN_EXPIRATION_MARGIN_SECONDS
      ) {
        clearAuthInfo();
        setUserInfo(null);
      } else {
        // todo refresh token and set use userInfo
        setUserInfo(authInfo.userInfo);
        touchLastAccess();
      }
    }
  }, []);

  const login = async ({ loginData, onSuccess, onError }: LoginProps) => {
    setIsLoading(true);
    try {
      const authInfo: AuthInfo = await signInUser(loginData.email, loginData.password);
      storeAuthInfo(authInfo);
      touchLastAccess();
      setUserInfo(authInfo.userInfo);
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setIsLoading(false);
      setUserInfo(null);
      clearLocalStore();
      if (onError) {
        console.log(e);
        onError(buildError(e));
      }
    }
  };

  const logout = async () => {
    await doLogout();
    setUserInfo(null);
  };

  const signUp = async ({ signupData, onSuccess, onError }: SignupProps) => {
    setIsLoading(true);
    try {
      await signUpUser(signupData);
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setIsLoading(false);
      if (onError) {
        onError(buildError(e));
      }
    }
  };

  const sendResetPasswordCode = async ({ email, onSuccess, onError }: SendResetPasswordCodeProps) => {
    setIsLoading(true);
    try {
      await forgotPassword(email);
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setIsLoading(false);
      if (onError) {
        onError(buildError(e));
      }
    }
  };

  const resetPassword = async ({ email, newPassword, confirmationCode, onSuccess, onError }: ResetPasswordProps) => {
    setIsLoading(true);
    try {
      await confirmResetPassword({ email, newPassword, confirmationCode });
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setIsLoading(false);
      if (onError) {
        onError(buildError(e));
      }
    }
  };

  const resendConfirmationCode = async ({ email, onSuccess, onError }: ResendConfirmationCodeProps) => {
    setIsLoading(true);
    try {
      await sendConfirmationCode(email);
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setIsLoading(false);
      if (onError) {
        onError(buildError(e));
      }
    }
  };

  const refreshUserInfo = async () => {
    const authInfo = getStoreAuthInfo();
    setUserInfo(authInfo?.userInfo ? authInfo.userInfo : null);
  };

  const getAuthStatus = () => checkAuthenticated();

  return (
    <UserContext.Provider
      value={{
        isLoading,
        userInfo,
        login,
        logout,
        signUp,
        sendResetPasswordCode,
        resetPassword,
        resendConfirmationCode,
        getAuthStatus,
        refreshUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

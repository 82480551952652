import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { ClinicMemberStatus, MemberClinicDTO } from '@src/common-utils/DataModels';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useEffect, useState } from 'react';

type DefaultClinicRendererProps = {
  data: MemberClinicDTO;
  context: {
    setDefaultClinic: (data: MemberClinicDTO) => void;
  };
};

export const DefaultClinicRenderer = ({ data: member, context: { setDefaultClinic } }: DefaultClinicRendererProps) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const canBeSetAsDefault = member?.status === ClinicMemberStatus.ACCEPTED;

  const handleSetDefaultClinic = () => {
    if (canBeSetAsDefault) {
      setIsUpdating(true);
      setDefaultClinic(member);
    }
  };

  useEffect(() => setIsUpdating(false), []);

  return (
    <>
      {!isUpdating && (
        <IconButton onClick={handleSetDefaultClinic} sx={{ cursor: canBeSetAsDefault ? 'pointer' : 'text' }}>
          {member.isDefault && <StarIcon fontSize="medium" color="success" />}
          {!member.isDefault && (
            <Tooltip title={canBeSetAsDefault ? 'Set as default clinic' : undefined} arrow>
              <StarBorderIcon fontSize="medium" color={canBeSetAsDefault ? 'primary' : 'disabled'} />
            </Tooltip>
          )}
        </IconButton>
      )}
      {isUpdating && (
        <IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            <CircularProgress size={16} />
          </Box>
        </IconButton>
      )}
    </>
  );
};

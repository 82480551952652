import { LoadingButton } from '@mui/lab';
import { CircularProgress, Link, SxProps, Theme, Typography } from '@mui/material';

type LinkLoadingButtonProps = {
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
  spinnerSize?: number;
  sx?: SxProps<Theme>;
};

export const LinkLoadingButton = ({ label, onClick, isLoading, spinnerSize = 16, sx }: LinkLoadingButtonProps) => {
  return (
    <Link
      component="a"
      underline="none"
      onClick={() => {
        if (onClick) onClick();
      }}
      sx={{ ...sx, cursor: 'pointer' }}
    >
      <Typography component="span" variant="body2">
        {label}
      </Typography>
      <LoadingButton loading={isLoading} loadingIndicator={<CircularProgress size={spinnerSize} />}></LoadingButton>
    </Link>
  );
};

import { Alert, Box, Button, Container } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { ClinicMenuRenderer } from '@src/components/clinic/clinic-management/menu/ClinicMenuRenderer';
import { ClinicDTO } from '@src/common-utils/DataModels';
import { caseInsensitiveComparator } from '@src/common-utils/Util';
import { ClinicMenItem } from '@src/components/clinic/clinic-management/menu/ClinicMenu';

const colDefs: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    maxWidth: 200,
    minWidth: 50,
    flex: 1,
    sort: 'asc',
    comparator: caseInsensitiveComparator,
  },
  {
    field: 'address',
    headerName: 'Address',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    minWidth: 100,
    flex: 2,
    comparator: caseInsensitiveComparator,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    minWidth: 40,
    flex: 1,
    comparator: caseInsensitiveComparator,
  },
  {
    cellRenderer: ClinicMenuRenderer,
    width: 64,
  },
];

type ClinicManagementGridProps = {
  data?: ClinicDTO[];
  onAddClinic: () => void;
  handleSelectClinicMenu: (acton: ClinicMenItem, data: ClinicDTO) => void;
};

export const ClinicManagementGrid = ({ data, onAddClinic, handleSelectClinicMenu }: ClinicManagementGridProps) => {
  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '500px',
        // justifyContent: 'center',
        // alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', mb: 1 }}>
        <Button size="medium" variant="contained" color="primary" onClick={onAddClinic} sx={{ textTransform: 'none' }}>
          Add Clinic
        </Button>
      </Box>
      <Box className="ag-theme-quartz" component="div" sx={{ height: '100%', width: '100%' }}>
        {data && data.length > 0 && (
          <AgGridReact
            rowData={data}
            columnDefs={colDefs}
            context={{
              handleSelectClinicMenu,
            }}
            gridOptions={{
              suppressCellFocus: true,
            }}
          />
        )}
        {(!data || data.length === 0) && (
          <Alert severity="info" sx={{ mt: 2 }}>
            No clinic found
          </Alert>
        )}
      </Box>
    </Container>
  );
};

import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCartContext } from '@src/contexts/CartContextProvider';
import { HeaderLink } from '@src/components/header/HeaderLink';
import { currencyFormatter } from '@src/common-utils/Util';

export const CartHeader = ({ onClose }: { onClose: () => void }) => {
  const { totals, clearMyCart, myCart } = useCartContext();

  const clearCart = () => {
    clearMyCart();
  };

  return (
    <AppBar position="static">
      <Toolbar variant="dense" disableGutters={true} sx={{ height: '64px' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pl: '8px',
            pr: '8px',
          }}
        >
          <Typography component="h1" variant="subtitle1" sx={{ mr: '16px' }}>
            Total: {currencyFormatter.format(totals)}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {myCart && myCart.items.length > 0 && (
              <HeaderLink text="Remove All" sx={{ mr: '16px' }} onClick={clearCart} variant={'body1'} />
            )}
            <IconButton
              size="medium"
              color="inherit"
              onClick={() => {
                if (onClose) onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

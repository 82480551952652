import { Alert, Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Nullable } from '@src/common-utils/Models';
import { ProductDTO } from '@src/common-utils/DataModels';
import { ProductCard } from '@src/components/product/ProductCard';
import { useSearchContext } from '@src/contexts/SearchContextProvider';
import {
  defaultPageSize,
  productsInfiniteQueryKey,
  useInfiniteProductsQuery,
  useProductsQuery,
} from '@src/queries/ProductQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '@mui/material/styles';

const skeletonProducts = Array.from({ length: 6 }, () => null);

export const Products = () => {
  const queryClient = useQueryClient();

  const { productSearchName } = useSearchContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const {
    data: pageData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch,
  } = useInfiniteProductsQuery({ searchName: productSearchName, pageSize: isMobile ? 12 : defaultPageSize });

  const [products, setProducts] = useState<Nullable<ProductDTO>[]>(skeletonProducts);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (pageData?.pages && !isLoading) {
      setProducts(pageData?.pages.flatMap((page) => page.products));
    }
  }, [pageData, isLoading]);

  useEffect(() => {
    if (productSearchName) {
      queryClient.invalidateQueries([...productsInfiniteQueryKey, { pageParam: null, searchName: productSearchName }]);
    }
    refetch();
  }, [productSearchName, queryClient, refetch]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid container spacing={4}>
        {products &&
          products.map((product, index) => (
            <Grid item xs={12} sm={6} md={4} key={product?.code || index}>
              <ProductCard product={product} />
            </Grid>
          ))}
      </Grid>
      {(!products || products.length === 0) && (
        <Alert variant="outlined" severity="info" sx={{ mt: '24px' }}>
          <Typography variant="subtitle1" sx={{ fontStyle: 'italic', fontWeight: 500 }}>
            There is no products matching your searching, please refine your search name and try again!
          </Typography>
        </Alert>
      )}
      <div ref={ref} />
    </Box>
  );
};

export const Products2 = () => {
  const { isLoading, isFetching, data } = useProductsQuery();

  const [products, setProducts] = useState<Nullable<ProductDTO>[]>(skeletonProducts);

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setProducts(data);
    }
  }, [isLoading, isFetching, data]);

  return (
    <Grid container spacing={4}>
      {products &&
        products.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} key={product?.code || index}>
            <ProductCard product={product} />
          </Grid>
        ))}
    </Grid>
  );
};

import { Alert, Box, Container, Typography } from '@mui/material';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import { caseInsensitiveComparator } from '@src/common-utils/Util';
import { DefaultClinicRenderer } from '@src/components/clinic/my-clinic/DefaultClinicRenderer';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { ClinicMemberStatus, MemberClinicDTO } from '@src/common-utils/DataModels';
import { MemeberStatusCellRenderer } from '@src/components/clinic/my-clinic/MemeberStatusCellRenderer';
import { Nullable } from '@src/common-utils/Models';

const colDefs: ColDef[] = [
  {
    // field: 'isDefault',
    lockPosition: true,
    resizable: false,
    cellRenderer: DefaultClinicRenderer,
    width: 48,
  },
  {
    field: 'clinic.name',
    headerName: 'Name',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    maxWidth: 200,
    minWidth: 50,
    flex: 1,
    sort: 'asc',
    comparator: caseInsensitiveComparator,
  },
  {
    field: 'clinic.address',
    headerName: 'Address',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    minWidth: 100,
    flex: 2,
    comparator: caseInsensitiveComparator,
  },
  {
    field: 'clinic.phoneNumber',
    headerName: 'Phone',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    minWidth: 40,
    flex: 1,
    comparator: caseInsensitiveComparator,
  },
  {
    field: 'status',
    headerName: 'Status',
    lockPosition: true,
    suppressAutoSize: true,
    resizable: true,
    minWidth: 40,
    flex: 1,
    // valueGetter: ({ data }: ValueGetterParams<MemberClinicDTO, string>) => (data ? StatusDisplay[data.status] : ''),
    comparator: caseInsensitiveComparator,
    cellRenderer: MemeberStatusCellRenderer,
  },
];

type MyClinicGridProps = {
  data: MemberClinicDTO[];
  error?: Nullable<Error>;
  handleSetDefaultClinic: (dto: MemberClinicDTO) => void;
  handleChangeStatus: (dto: MemberClinicDTO, newStatus: ClinicMemberStatus) => void;
};

export const MyClinicGrid = ({ data, error, handleSetDefaultClinic, handleChangeStatus }: MyClinicGridProps) => {
  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '500px',
        // justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', mb: 1, display: 'flex', alignItems: 'center' }}>
        <LocalPharmacyIcon color="info" sx={{ mr: 1 }} />
        <Box>
          <Typography variant="h6">My Clinic</Typography>
        </Box>
      </Box>
      <Box className="ag-theme-quartz" component="div" sx={{ height: '100%', width: '100%' }}>
        {data && data.length > 0 && !error && (
          <AgGridReact
            rowData={data}
            columnDefs={colDefs}
            context={{
              setDefaultClinic: handleSetDefaultClinic,
              changeStatus: handleChangeStatus,
            }}
            gridOptions={{
              suppressCellFocus: true,
            }}
          />
        )}
        {(!data || data.length === 0) && !error && (
          <Alert severity="info" sx={{ mt: 2 }}>
            You are not associated with any clinics
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error.message}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

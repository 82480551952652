export const getEpochTime = (date?: Date) => Math.floor((date ? date.getTime() : Date.now()) / 1000);

export const calculateTotals = ({
  price,
  quantity,
  fixed = 2,
}: {
  price: number;
  quantity: number;
  fixed?: number;
}): number => Number((quantity * price).toFixed(fixed));

export const currencyFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
  minimumFractionDigits: 2, // Minimum number of decimal places
  maximumFractionDigits: 2, // Maximum number of decimal places
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const caseInsensitiveComparator = (valueA: any, valueB: any) => {
  if (valueA === null || valueA === undefined) {
    return -1;
  }
  if (valueB === null || valueB === undefined) {
    return 1;
  }
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

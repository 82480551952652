import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { PrimaryColor } from '@src/components/common/Colors';

const NumberInputButton = ({
  value,
  onclick,
  disabled = false,
}: {
  value: string | number;
  onclick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      onClick={() => {
        if (onclick) onclick();
      }}
      size="small"
      sx={{ pt: 0, pb: 0 }}
      disabled={disabled}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 600, width: '16px', color: PrimaryColor }}>
        {value}
      </Typography>
    </Button>
  );
};

type NumberInputProps = {
  initialValue?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
};

export const NumberInput = ({ initialValue = 1, onChange, min = 1, max = 99 }: NumberInputProps) => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    if (onChange) {
      onChange(count);
    }
  }, [count, onChange]);

  const handleIncrement = () => {
    setCount((prevCount) => (prevCount < max ? prevCount + 1 : max));
  };

  const handleDecrement = () => {
    setCount((prevCount) => (prevCount > min ? prevCount - 1 : min));
  };

  return (
    <ButtonGroup
      variant="outlined"
      color="primary"
      size="small"
      sx={{
        '& .MuiButton-root:focus': {
          outline: 'none',
        },
      }}
    >
      <NumberInputButton value="-" onclick={handleDecrement} disabled={count === min} />
      <NumberInputButton value={count} disabled={true} />
      <NumberInputButton value="+" onclick={handleIncrement} disabled={count === max} />
    </ButtonGroup>
  );
};

type QuantityInputprops = {
  value: number;
  min?: number;
  max?: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
};

export const QuantityInput = ({ value, min = 0, max = 99, onIncrement, onDecrement }: QuantityInputprops) => {
  return (
    <ButtonGroup
      variant="outlined"
      color="primary"
      size="small"
      sx={{
        '& .MuiButton-root:focus': {
          outline: 'none',
        },
      }}
    >
      <NumberInputButton
        value="-"
        onclick={() => {
          if (onDecrement) {
            onDecrement();
          }
        }}
        disabled={value === min}
      />
      <NumberInputButton value={value} disabled={true} />
      <NumberInputButton
        value="+"
        onclick={() => {
          if (onIncrement) {
            onIncrement();
          }
        }}
        disabled={value === max}
      />
    </ButtonGroup>
  );
};

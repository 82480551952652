import { Nullable } from '@src/common-utils/Models';
import {
  CartItem,
  DiscountCartItem,
  LocalStoreCartItem,
  ProductDTO,
  ProductDiscount,
} from '@src/common-utils/DataModels';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { touchLastAccess } from '@src/common-utils/StoreUtil';
import { calculateTotals } from '@src/common-utils/Util';
import { fetchProducts } from '@src/queries/ProductQuery';

const cartKeyPrefix = 'dentalware_cart';

const getCartStoragekey = (userId: string) => `${cartKeyPrefix}-${userId}`;

type MyCart = {
  items: CartItem[];
};

type MyLocalStoreCart = {
  items: LocalStoreCartItem[];
};

const clearMyLocalStoreCart = (userId: string) => {
  localStorage.removeItem(getCartStoragekey(userId));
};

const setMyLocalStoreCart = (userId: string, myCart: Nullable<MyCart>) => {
  if (myCart) {
    const localStoreCart: MyLocalStoreCart = {
      items: myCart.items.map((item) => ({ productCode: item.productCode, quantity: item.quantity })),
    };
    localStorage.setItem(getCartStoragekey(userId), JSON.stringify(localStoreCart));
  } else {
    clearMyLocalStoreCart(userId);
  }
};

const getMyLocalStoreCart = (userId: string): Nullable<MyLocalStoreCart> => {
  const cartInfoJson = localStorage.getItem(`${cartKeyPrefix}-${userId}`);
  return cartInfoJson ? JSON.parse(cartInfoJson) : null;
};

const removeItemFromMyCart = (myCart: Nullable<MyCart>, index: number): Nullable<MyCart> => {
  if (!myCart || myCart.items.length === 0) {
    return myCart;
  }

  if (index < 0 || index > myCart.items.length) {
    return myCart;
  }
  return { items: [...myCart.items.slice(0, index), ...myCart.items.slice(index + 1)] };
};

type CartContextType = {
  myCart: Nullable<MyCart>;
  subTotals: number;
  subTotalBeforeDiscount: number;
  gst: number;
  totals: number;
  discountCartItems: Nullable<DiscountCartItem[]>;
  addProductToCart: (product: ProductDTO, quantity: number) => void;
  addCartItem: (item: Omit<CartItem, 'itemTotals'>) => void;
  removeCartItem: (index: number) => void;
  setQuantity: (index: number, quantity: number) => void;
  increaseQuantity: (index: number) => void;
  decreaseQuantity: (index: number) => void;
  addCartItems: (items: Omit<CartItem, 'itemTotals'>[]) => void;
  clearMyCart: () => void;
};

const CartContext = createContext<CartContextType>({
  myCart: null,
  totals: 0,
  subTotals: 0,
  subTotalBeforeDiscount: 0,
  gst: 0,
  discountCartItems: null,
  addProductToCart: () => {
    // no ops
  },
  addCartItem: () => {
    // no ops
  },
  setQuantity: () => {
    // no ops
  },
  increaseQuantity: () => {
    // no ops
  },
  decreaseQuantity: () => {
    // no ops
  },
  removeCartItem: () => {
    // no ops
  },
  addCartItems: () => {
    // no ops
  },
  clearMyCart: () => {
    // no ops
  },
});

const GST: number = 0.15;

const calculateDiscountAmount = ({
  quantity,
  unitPrice,
  discount,
}: {
  quantity: number;
  unitPrice: number;
  discount: ProductDiscount;
}): number => {
  switch (discount.discountType) {
    case 'BUY_X_GET_Y_FREE': {
      // Y value represents the free items for the discount
      const freeItemsPerDiscount = discount.discountYValue || 1;

      // Calculate the number of free items (Y)
      const freeItemsCount =
        Math.floor(quantity / (discount.discountValue + freeItemsPerDiscount)) * freeItemsPerDiscount;

      // Calculate the chargeable quantity (excluding free items)
      // const chargeableQuantity = quantity - freeItemsCount;

      // Adjust the total by subtracting the cost of free items
      // return chargeableQuantity * unitPrice;
      // return calculateTotals({ price: unitPrice, quantity: chargeableQuantity });

      return calculateTotals({ price: unitPrice, quantity: freeItemsCount });
    }
    case 'PERCENTAGE':
      return calculateTotals({ price: unitPrice * (discount.discountValue / 100), quantity });
    default:
      return 0;
  }
};

export const CartContextProvider = ({ children }: { children: ReactNode }) => {
  const { userInfo } = useUserContext();

  const [myCart, setMyCart] = useState<Nullable<MyCart>>(null);
  const [totals, setTotals] = useState<number>(0);
  const [subTotals, setSubTotals] = useState<number>(0);
  const [subTotalBeforeDiscount, setSubTotalBeforeDiscount] = useState<number>(0);
  const [gst, setGst] = useState<number>(0);
  const [discountCartItems, setDiscountCartItems] = useState<Nullable<DiscountCartItem[]>>(null);

  // const { dynamicRefetch, data: cartProducts } = useProductsQuery({ lazy: true });
  // const dynamicRefetchRef = useRef(dynamicRefetch);
  // dynamicRefetchRef.current = dynamicRefetch;
  // const cartProductsRef = useRef(cartProducts);
  // cartProductsRef.current = cartProducts;

  // useEffect(() => {
  //   dynamicRefetchRef.current = dynamicRefetch;
  //   cartProductsRef.current = cartProducts;
  // }, [dynamicRefetch, cartProducts]);

  useEffect(() => {
    (async () => {
      if (!userInfo) {
        setMyCart(null);
        return;
      }

      const localStoreCart = getMyLocalStoreCart(userInfo.id);
      if (!localStoreCart || localStoreCart.items.length === 0) {
        setMyCart(null);
        return;
      }
      const cartProducts = await fetchProducts({
        productCodes: localStoreCart.items.map((item) => item.productCode),
      });

      const myCart: Nullable<MyCart> = localStoreCart
        ? {
            items: localStoreCart.items
              ? localStoreCart.items.map((storedItem) => {
                  const product = cartProducts?.find((cartProduct) => cartProduct.code === storedItem.productCode);
                  return {
                    ...storedItem,
                    name: product?.name || '',
                    packing: product?.packing || '',
                    unit: product?.unit || '',
                    price: product?.price || 0,
                    itemTotals: calculateTotals({ price: product?.price || 0, quantity: storedItem.quantity }),
                    imageURL: product?.images[0].url,
                    discount: product?.discount,
                  };
                })
              : [],
          }
        : null;
      setMyCart(myCart);
    })();
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const amount = myCart?.items ? myCart?.items.reduce((accumulator, item) => accumulator + item.itemTotals, 0) : 0;

    // calculate discount
    const itemsWithDiscount = myCart?.items
      ? myCart.items
          .filter((item) => !!item.discount)
          .reduce((accumulator: CartItem[], currentItem: CartItem) => {
            // Find the existing item in the accumulator array with the same productCode
            const existingItem = accumulator.find((item) => item.productCode === currentItem.productCode);

            if (existingItem) {
              // If an item with the same productCode exists, update its quantity
              existingItem.quantity = existingItem.quantity + currentItem.quantity;
              return accumulator;

              // const updatedItem = { ...existingItem, quantity: existingItem.quantity + currentItem.quantity };
              // // Replace the existing item in the accumulator with the updated item
              // return [...accumulator.filter((item) => item.productCode !== existingItem.productCode), updatedItem];
            } else if (currentItem.discount !== null) {
              // If no item with the same productCode exists, and the discount is not null, add a new item to the accumulator
              return [...accumulator, { ...currentItem }];
            }
            return accumulator;
          }, [])
      : null;

    const discountItems =
      itemsWithDiscount && itemsWithDiscount.length > 0
        ? itemsWithDiscount
            .map((item) => ({
              productCode: item.productCode,
              productName: item.name,
              discountAmount: calculateDiscountAmount({
                quantity: item.quantity,
                unitPrice: item.price,
                discount: item.discount!,
              }),
              description: item.discount!.description,
            }))
            .filter((item) => item.discountAmount > 0)
        : null;

    setDiscountCartItems(discountItems);
    // console.log('discountItems', discountItems);

    setSubTotalBeforeDiscount(Number(amount.toFixed(2)));

    const subTotalAmount = Number(
      (discountItems && discountItems.length > 0
        ? amount - discountItems.reduce((accumulator, currentItem) => accumulator + currentItem.discountAmount, 0)
        : amount
      ).toFixed(2),
    );
    setSubTotals(subTotalAmount);
    const gstAmount = Number((subTotalAmount * GST).toFixed(2));
    setGst(Number(gstAmount));
    setTotals(subTotalAmount + gstAmount);

    setMyLocalStoreCart(userInfo.id, myCart);
  }, [myCart, userInfo]);

  return (
    <CartContext.Provider
      value={{
        myCart,
        totals,
        subTotals,
        subTotalBeforeDiscount,
        gst,
        discountCartItems,
        addCartItem: (item) => {
          if (!userInfo) {
            return;
          }
          const newItem: CartItem = {
            ...item,
            itemTotals: calculateTotals({ price: item.price, quantity: item.quantity }),
          };
          setMyCart((preCart) => {
            if (preCart) {
              return { items: [...preCart.items, newItem] };
            } else {
              return { items: [newItem] };
            }
          });
          touchLastAccess();
        },
        addProductToCart: (product: ProductDTO, quantity: number) => {
          if (!userInfo) {
            return;
          }
          const newItem: CartItem = {
            productCode: product.code,
            name: product.name,
            packing: product.packing,
            unit: product.unit,
            price: product.price,
            quantity,
            imageURL: product.images[0].url,
            discount: product.discount,
            itemTotals: calculateTotals({ price: product.price, quantity }),
          };
          setMyCart((preCart) => {
            if (preCart) {
              return { items: [...preCart.items, newItem] };
            } else {
              return { items: [newItem] };
            }
          });
          touchLastAccess();
        },
        removeCartItem: (index: number) => {
          if (!userInfo) {
            return;
          }
          setMyCart((preCart) => {
            return removeItemFromMyCart(preCart, index);
          });
          touchLastAccess();
        },
        addCartItems: (items: Omit<CartItem, 'itemTotals'>[]) => {
          if (!userInfo) {
            return;
          }
          const newItems: CartItem[] = items.map((item) => ({
            ...item,
            itemTotals: calculateTotals({ price: item.price, quantity: item.quantity }),
          }));

          setMyCart((preCart) => {
            if (preCart) {
              return { items: [...preCart.items, ...newItems] };
            } else {
              return { items: newItems };
            }
          });
          touchLastAccess();
        },
        clearMyCart: () => {
          if (!userInfo) {
            return;
          }
          setMyCart(null);
          touchLastAccess();
        },
        increaseQuantity: (index: number) => {
          if (!userInfo) {
            return;
          }
          setMyCart((preCart) => {
            if (preCart && preCart.items.length > 0) {
              if (index < 0 || index >= preCart.items.length) {
                return preCart;
              }

              const cartItem = preCart.items[index];
              cartItem.quantity += 1;
              cartItem.itemTotals = calculateTotals({ price: cartItem.price, quantity: cartItem.quantity });
              return { items: [...preCart.items] };
            } else {
              return preCart;
            }
          });
          touchLastAccess();
        },
        decreaseQuantity: (index: number) => {
          if (!userInfo) {
            return;
          }
          setMyCart((preCart) => {
            if (preCart && preCart.items.length > 0) {
              if (index < 0 || index >= preCart.items.length) {
                return preCart;
              }

              const cartItem = preCart.items[index];
              if (cartItem.quantity <= 1) {
                // remove this item
                return removeItemFromMyCart(preCart, index);
              }

              cartItem.quantity -= 1;
              cartItem.itemTotals = calculateTotals({ price: cartItem.price, quantity: cartItem.quantity });
              return { items: [...preCart.items] };
            } else {
              return preCart;
            }
          });

          touchLastAccess();
        },
        setQuantity: (index: number, quantity: number) => {
          if (!userInfo) {
            return;
          }
          setMyCart((preCart) => {
            if (preCart && preCart.items.length > 0) {
              if (index < 0 || index >= preCart.items.length) {
                return preCart;
              }

              const cartItem = preCart.items[index];
              if (quantity < 1) {
                return removeItemFromMyCart(preCart, index);
              }

              cartItem.quantity = quantity;
              cartItem.itemTotals = calculateTotals({ price: cartItem.price, quantity: cartItem.quantity });
              return { items: [...preCart.items] };
            } else {
              return preCart;
            }
          });

          touchLastAccess();
        },
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);

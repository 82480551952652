import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { ProductDTO } from '@src/common-utils/DataModels';
import { useState } from 'react';
import { useCartContext } from '@src/contexts/CartContextProvider';
import { ImageSlider } from '@src/components/image-slider/ImageSlider';
import { NumberInput } from '@src/components/common/NumberInput';
import CloseIcon from '@mui/icons-material/Close';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { ErrorColor } from '@src/components/common/Colors';

type ProductDetailsProps = {
  product: ProductDTO;
  onClose?: () => void;
  readonly: boolean;
};

const imgHeight = '280px';

export const ProductDetails = ({ product, onClose, readonly }: ProductDetailsProps) => {
  const [quantity, setQuantity] = useState(1);

  const { addProductToCart } = useCartContext();

  const addToCartHandler = () => {
    addProductToCart(product, quantity);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }
        action={
          <IconButton
            aria-label="information"
            title="close"
            size="medium"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            <CloseIcon sx={{ color: '#5c87cc' }} fontSize="medium" />
          </IconButton>
        }
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
            <Typography variant="h6">{product.code} -</Typography>
            {!readonly && (
              <Typography variant="subtitle1">
                ${product.price}/{product.unit}
              </Typography>
            )}
            <Typography variant="subtitle1">{product.packing}</Typography>
          </Box>
        }
        subheader={<Typography variant="subtitle1">{product.name}</Typography>}
        sx={{ height: '96px' }}
      />
      {product.images.length === 1 && (
        <CardMedia
          component="img"
          // sx={{ pt: '65%' }}
          sx={{
            height: imgHeight,
            objectFit: 'contain',
            transition: 'transform .5s', // Smooth transition
            // '&:hover': {
            //   transform: 'scale(1.5)', // Zoom in
            // },
          }}
          image={product.images[0].url}
        />
      )}
      {product.images.length > 1 && (
        <ImageSlider
          height={imgHeight}
          items={product.images.map((image) => ({ name: image.name, imageUrl: image.url }))}
        />
      )}
      <CardContent sx={{ flexGrow: 1, maxHeight: '200px', overflow: 'auto' }}>
        {product.description && (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#e0e0e0', padding: 1, mb: '4px' }}
          >
            <Typography variant="subtitle1">{product.description}</Typography>
          </Box>
        )}
        {!readonly && product.discount && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <NewReleasesIcon color="error" />
            </Box>
            <Typography variant="subtitle1" sx={{ color: ErrorColor, ml: 0.5, mb: 0.5, fontStyle: 'italic' }}>
              {product.discount.description}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', mb: 1 }}>
          {product.specification.map((spec, index) => (
            <Typography variant="body1" key={`${product.code}-detail-spec-${index}`}>
              {spec}
            </Typography>
          ))}
        </Box>
      </CardContent>
      <Divider />
      {!readonly && (
        <CardActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <NumberInput onChange={setQuantity} />
          <Button variant="outlined" size="small" onClick={addToCartHandler} sx={{ textTransform: 'none' }}>
            Add to cart
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

import { Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Nullable } from '@src/common-utils/Models';

export type ClinicMenItem = 'invite-member' | 'edit-clinic' | 'delete-clinic' | 'clinic-members';

type ClinicMenuProps = {
  anchorEl: Nullable<HTMLElement>;
  onSelectMenuItem: (menuItem: ClinicMenItem) => void;
  onClose: () => void;
};

export const ClinicMenu = ({ anchorEl, onSelectMenuItem, onClose }: ClinicMenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem
        onClick={() => {
          onSelectMenuItem('invite-member');
        }}
      >
        <PersonAddIcon fontSize="small" sx={{ mr: 1 }} />
        Invite Member
      </MenuItem>
      <MenuItem
        onClick={() => {
          onSelectMenuItem('clinic-members');
        }}
      >
        <PeopleAltIcon fontSize="small" sx={{ mr: 1 }} />
        Clinic Members
      </MenuItem>
      <MenuItem
        onClick={() => {
          onSelectMenuItem('edit-clinic');
        }}
      >
        <EditIcon fontSize="small" sx={{ mr: 1 }} />
        Edit Clinic
      </MenuItem>
      <MenuItem
        onClick={() => {
          onSelectMenuItem('delete-clinic');
        }}
      >
        <DeleteForeverIcon fontSize="small" sx={{ mr: 1 }} />
        Delete Clinic
      </MenuItem>
    </Menu>
  );
};

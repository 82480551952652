import { InputBase, Typography, useMediaQuery } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, MUIStyledCommonProps } from '@mui/system';
import theme from '@src/theme';
import { LoadingButton } from '@mui/lab';

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  // borderRadius: theme.shape.borderRadius,
  borderRadius: '4px 0 0 4px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  // marginRight: theme.spacing(4),
  marginLeft: 0,
  width: '420px',
  [theme.breakpoints.down('md')]: {
    // marginLeft: theme.spacing(3),
    width: '300px',
  },
  [theme.breakpoints.down('sm')]: {
    // marginLeft: theme.spacing(0),
    // marginRight: theme.spacing(0),
    width: '100%',
  },
}));

type SearchIconWrapperProps = MUIStyledCommonProps & {
  anchor: 'left' | 'right';
};

const SearchIconWrapper = styled('div')<SearchIconWrapperProps>(({ theme, anchor }) => ({
  position: 'absolute',
  padding: theme.spacing(0, 1),
  height: '100%',
  [anchor]: 0,
  // pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 100,
  cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create('width'),
    // paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    // outerHeight: '48px',
    paddingLeft: '12px',
    height: '26px',
    width: '360px',
    // [theme.breakpoints.down('lg')]: {
    //   // marginLeft: theme.spacing(3),
    //   width: '300px',
    // },
    [theme.breakpoints.down('md')]: {
      // marginLeft: theme.spacing(3),
      width: '240px',
    },
    [theme.breakpoints.down('sm')]: {
      // marginLeft: theme.spacing(3),
      width: '100%',
      // width: '150px',
      marginRight: theme.spacing(3),
    },
  },
}));

type HeaderSearchProps = {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  onSearch: () => void;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const disabledColor = '#a0a0a0';

export const HeaderSearch = ({
  value,
  onChange,
  onClear,
  onSearch,
  placeholder,
  disabled,
  isLoading,
}: HeaderSearchProps) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0',
        cursor: disabled ? 'not-allowed' : 'undefined',
      }}
    >
      <Search sx={{ display: 'flex', mr: 0, flexFlow: 1 }}>
        {value && (
          <SearchIconWrapper anchor="right" onClick={onClear}>
            <ClearIcon />
          </SearchIconWrapper>
        )}
        <StyledInputBase
          placeholder={placeholder || 'Search...'}
          inputProps={{ 'aria-label': 'search' }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Search>

      <LoadingButton
        component="label"
        variant="contained"
        startIcon={!isSmall && <SearchIcon sx={{ color: disabled ? disabledColor : undefined }} />}
        size="medium"
        sx={{
          backgroundColor: '#3c52b2',
          color: '#fff',
          '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.05),
          },
          boxShadow: 'none',
          height: '42px',
          borderRadius: '0 4px 4px 0',
          borderLeft: '1px',
          textTransform: 'none',
          flexGrow: 0,
        }}
        onClick={onSearch}
        disabled={disabled}
        loading={isLoading}
        loadingPosition="start"
      >
        <Typography
          variant={isSmall ? 'subtitle2' : 'subtitle1'}
          sx={{ fontWeight: 600, color: disabled ? disabledColor : undefined }}
        >
          Search
        </Typography>
      </LoadingButton>
    </Box>
  );
};

import { Card, CardMedia } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

type ImageSliderItem = {
  name?: string;
  description?: string;
  imageUrl: string;
  height?: string;
};

export const ImageSlider = ({ items, height = '360px' }: { items: ImageSliderItem[]; height?: string }) => {
  return (
    <Carousel autoPlay={false} stopAutoPlayOnHover={false} animation="slide" swipe={true} cycleNavigation={true}>
      {items.map((item, i) => (
        <Card key={i}>
          <CardMedia
            component="img"
            image={item.imageUrl}
            alt={item.name}
            sx={{
              height,
              objectFit: 'contain',
              // '&:hover': {
              //   transform: 'scale(1.5)',
              // },
            }}
          />
          {/* <CardContent>
            <Typography variant="h5">{item.name}</Typography>
            <Typography variant="body2">{item.description}</Typography>
          </CardContent> */}
        </Card>
      ))}
    </Carousel>
  );
};

import React, { useEffect } from 'react';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { AuthStatus, CognitoGroup } from '@src/common-utils/AuthModels';
import { useNavigate } from 'react-router';
import { ROUTES } from '@src/layout/RoutePage';

export const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options?: { redirect?: string; groups?: CognitoGroup[] },
) => {
  const WithAuth = (props: P) => {
    const { getAuthStatus, logout, userInfo } = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
      if (!userInfo || getAuthStatus() === AuthStatus.EXPIRED) {
        logout();
        // Check if redirect is provided and append it as a query parameter if it is
        const signInRoute = options?.redirect
          ? `${ROUTES.signin}?redirect=${encodeURIComponent(options.redirect)}`
          : ROUTES.signin;
        navigate(signInRoute);
      } else if (
        options?.groups &&
        !options.groups.some((group) => userInfo.groups && userInfo.groups.includes(group))
      ) {
        navigate(ROUTES.home);
      }
    }, [getAuthStatus, userInfo, logout, navigate]);

    // Render the wrapped component if the user is authenticated and has permission
    return <WrappedComponent {...props} />;
  };

  return WithAuth;
};

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { CartContextProvider } from '@src/contexts/CartContextProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import { SearchContextProvider } from '@src/contexts/SearchContextProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NotificationProvider } from '@src/contexts/NotificationProvider';

// const baseURL = import.meta.env.BASE_URL;

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // retry: (failureCount, error: unknown) => {
        //   if (error instanceof FromServerError) {
        //     const status = error.response?.status;

        //     // network error (no response)
        //     if (status === undefined) {
        //       return failureCount < 3;
        //     }

        //     return !!queryRetries[+status] && failureCount < queryRetries[+status];
        //   }

        //   return false;
        // },
        retry: 1,
        cacheTime: 5 * 30 * 1000,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <ConfirmProvider>
      <NotificationProvider>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <CartContextProvider>
              <SearchContextProvider>{children}</SearchContextProvider>
            </CartContextProvider>
          </UserContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </NotificationProvider>
    </ConfirmProvider>
  );
};

import { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ClinicDTO } from '@src/common-utils/DataModels';
import { ClinicMenItem, ClinicMenu } from '@src/components/clinic/clinic-management/menu/ClinicMenu';

type ClinicRendererProps = {
  data: ClinicDTO;
  context: {
    handleSelectClinicMenu: (action: ClinicMenItem, data: ClinicDTO) => void;
  };
};

export const ClinicMenuRenderer = ({ data, context: { handleSelectClinicMenu } }: ClinicRendererProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenuItem = (item: ClinicMenItem) => {
    handleClose();
    handleSelectClinicMenu(item, data);
  };

  return (
    <Box>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <ClinicMenu anchorEl={anchorEl} onClose={handleClose} onSelectMenuItem={handleSelectMenuItem} />
    </Box>
  );
};

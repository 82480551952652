import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppIcon } from '@src/icon/Icon';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, useMediaQuery } from '@mui/material';
import { HeaderSearch } from '@src/components/header/Search';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { HeaderMenu, HeaderMenuItem } from '@src/components/header/HeaderMenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { HeaderLink } from '@src/components/header/HeaderLink';
import { useCartContext } from '@src/contexts/CartContextProvider';
import { ROUTES } from '@src/layout/RoutePage';
import HomeIcon from '@mui/icons-material/Home';
import { useSearchContext } from '@src/contexts/SearchContextProvider';
import { Nullable } from '@src/common-utils/Models';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const showSearch = (path: string) => [ROUTES.home, ROUTES.userManagement].includes(path);

const searchPlaceholder = (path: string) => {
  const placesholders = {
    [ROUTES.home]: 'Search...',
    [ROUTES.userManagement]: 'User Email... ',
  };

  return placesholders[path] || 'Search...';
};

const searchValidator = (path: string): ((value: string) => boolean) => {
  const validators: Record<string, (value: string) => boolean> = {
    [ROUTES.home]: (value: string) => !!value,
    [ROUTES.userManagement]: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  };

  return validators[path] || ((value: string) => !!value);
};

const needBackButtonPaths = [
  ROUTES.resetPassword,
  ROUTES.profile,
  ROUTES.userManagement,
  ROUTES.clinicManagement,
  ROUTES.myClinic,
];

const GapSmall = 1;
const GapMedium = 2;

export const Header = ({ onCartClick }: { onCartClick?: () => void }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { setProductSearchName, setUserSearchCriteria, isLoading } = useSearchContext();

  const { userInfo, logout } = useUserContext();

  const { myCart } = useCartContext();
  const cartItemsNumber = myCart?.items.length ?? 0;

  const navigate = useNavigate();

  const location = useLocation();

  const isHomePage = ROUTES.home === location.pathname;
  const shouldShowSearch = showSearch(location.pathname);
  const placeholder = searchPlaceholder(location.pathname);
  const validator = searchValidator(location.pathname);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [productSearchKeyword, setProductSearchKeyword] = useState<Nullable<string>>(null);
  const [productSearchDisabled, setProductSearchDisabled] = useState(true);

  const [userSearchKeyword, setUserSearchKeyword] = useState<Nullable<string>>(null);
  const [userSearchDisabled, setUserSearchDisabled] = useState(true);

  const handleShoppingCartClick = () => {
    if (onCartClick) {
      onCartClick();
    }
  };

  const handleResetPassword = () => {
    navigate(ROUTES.forgotPassowrd);
  };

  const handleUpdateProfile = () => {
    navigate(ROUTES.profile, { state: {} });
  };

  const handleAccountClick = (event: MouseEvent<HTMLElement>) => {
    if (!userInfo) {
      navigate(ROUTES.signin);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHeaderMenuClose = () => {
    setAnchorEl(null);
  };

  const [needBack, setNeedBack] = useState(false);

  useEffect(() => {
    if (isSmall && needBackButtonPaths.includes(location.pathname)) {
      setNeedBack(true);
    } else {
      setNeedBack(false);
    }
  }, [isSmall, location]);

  const handleMenuItemSelect = (menuItem: HeaderMenuItem) => {
    handleHeaderMenuClose();
    switch (menuItem) {
      case 'login':
        navigate(ROUTES.signin);
        break;
      case 'my-cart':
        handleShoppingCartClick();
        break;
      case 'reset-password':
        handleResetPassword();
        break;
      case 'profile':
        handleUpdateProfile();
        break;
      case 'logout':
        logout();
        break;
      case 'user-management':
        navigate(ROUTES.userManagement);
        break;
      case 'clinic-management':
        navigate(ROUTES.clinicManagement);
        break;
      case 'my-clinic':
        navigate(ROUTES.myClinic);
        break;
      default:
    }
  };

  const handleSearchKeywordChange = (newKeyword: string) => {
    const keyword = newKeyword.trim();
    switch (location.pathname) {
      case ROUTES.home:
        setProductSearchKeyword(keyword);
        break;
      case ROUTES.userManagement:
        setUserSearchKeyword(keyword);
        break;
      default:
        break;
    }
  };

  const handleSearchKeywordClear = () => {
    switch (location.pathname) {
      case ROUTES.home:
        setProductSearchKeyword('');
        setProductSearchName('');
        break;
      case ROUTES.userManagement:
        setUserSearchKeyword('');
        setUserSearchCriteria(null);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    switch (location.pathname) {
      case ROUTES.home:
        setProductSearchName(productSearchKeyword);
        break;
      case ROUTES.userManagement:
        setUserSearchCriteria({ email: userSearchKeyword });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (productSearchKeyword === '') {
      setProductSearchName('');
    }
  }, [productSearchKeyword, setProductSearchName]);

  useEffect(() => {
    setProductSearchDisabled(!validator(productSearchKeyword || ''));
  }, [productSearchKeyword, validator]);

  useEffect(() => {
    if (userSearchKeyword === '') {
      setUserSearchCriteria(null);
    }
  }, [userSearchKeyword, setUserSearchCriteria]);

  useEffect(() => {
    setUserSearchDisabled(!validator(userSearchKeyword || ''));
  }, [userSearchKeyword, validator]);

  const getButtonIconSize = useCallback(() => (isSmall ? 'small' : 'medium'), [isSmall]);

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters={true} sx={{ height: '64px' }}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(1),
            paddingRight: isSmall ? theme.spacing(1) : theme.spacing(3),
            width: '100%',
            gap: GapSmall,
          }}
        >
          {!isSmall && (
            <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate('/')}>
              <IconButton size={getButtonIconSize()} color="inherit">
                <AppIcon name="guruHealth" size="lg" />
              </IconButton>
              <IconButton size={getButtonIconSize()} color="inherit">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: 'none', sm: 'block', cursor: 'pointer' } }}
                >
                  GuruHealth
                </Typography>
              </IconButton>
              {!isHomePage && (
                <IconButton size={getButtonIconSize()} color="inherit">
                  <HomeIcon />
                </IconButton>
              )}
            </Box>
          )}

          {isSmall && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              {!!userInfo && (
                <>
                  <IconButton size={getButtonIconSize()} color="inherit" onClick={handleMenuClick}>
                    <MenuIcon />
                  </IconButton>
                  <HeaderMenu
                    anchorElement={anchorEl}
                    onClose={handleHeaderMenuClose}
                    onMenuItemSelect={handleMenuItemSelect}
                    userInfo={userInfo}
                  />
                  {needBack && (
                    <IconButton
                      size={getButtonIconSize()}
                      sx={{ color: 'white' }}
                      onClick={() => navigate(ROUTES.home)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                </>
              )}
              {!userInfo && (
                <IconButton size={getButtonIconSize()} color="inherit" onClick={() => navigate('/')}>
                  <AppIcon name="guruHealth" size="md" />
                </IconButton>
              )}
            </Box>
          )}

          {shouldShowSearch && (
            <Box sx={{ flexGrow: 1 }}>
              {location.pathname === ROUTES.home && (
                <HeaderSearch
                  onChange={handleSearchKeywordChange}
                  onClear={handleSearchKeywordClear}
                  onSearch={handleSearch}
                  placeholder={placeholder}
                  value={productSearchKeyword || ''}
                  disabled={productSearchDisabled}
                  isLoading={isLoading}
                />
              )}
              {location.pathname === ROUTES.userManagement && (
                <HeaderSearch
                  onChange={handleSearchKeywordChange}
                  onClear={handleSearchKeywordClear}
                  onSearch={handleSearch}
                  placeholder={placeholder}
                  value={userSearchKeyword || ''}
                  disabled={userSearchDisabled}
                  isLoading={isLoading}
                />
              )}
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', gap: GapMedium }}>
            {userInfo && (
              <IconButton
                size={getButtonIconSize()}
                edge="end"
                aria-haspopup="true"
                color="inherit"
                onClick={handleShoppingCartClick}
                sx={{ mr: isSmall ? theme.spacing(1) : 0 }}
              >
                <Badge badgeContent={cartItemsNumber} color="error" showZero={true}>
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            )}
            {(!userInfo || !isSmall) && (
              <>
                <IconButton
                  size={getButtonIconSize()}
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleAccountClick}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: GapSmall }}>
                    <AccountCircle />
                    {!isSmall && (
                      <Typography noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {!userInfo && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <HeaderLink text="Log in" sx={{}} onClick={() => navigate(ROUTES.signin)} />
                            <Box>|</Box>
                            <HeaderLink
                              text="Sign Up"
                              sx={{}}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(ROUTES.signup);
                              }}
                            />
                          </Box>
                        )}
                        {userInfo && <>{userInfo.firstName}</>}
                      </Typography>
                    )}
                  </Box>
                </IconButton>
                <HeaderMenu
                  anchorElement={anchorEl}
                  onClose={handleHeaderMenuClose}
                  onMenuItemSelect={handleMenuItemSelect}
                  userInfo={userInfo}
                />
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

import { Box, Skeleton } from '@mui/material';
import { Nullable } from '@src/common-utils/Models';
import { useState } from 'react';

type ReloadableImageProps = {
  url?: Nullable<string>;
  width?: string;
  height?: string;
  onLoad?: () => void;
  onError?: () => void;
};

export const ReloadableImage = ({ url, width, height, onError, onLoad }: ReloadableImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleError = () => {
    setImageLoaded(false);
    if (onError) {
      onError();
    }
  };

  const handleOnLoad = () => {
    setImageLoaded(true);
    if (onLoad) {
      onLoad();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {(!imageLoaded || !url) && <Skeleton animation="wave" sx={{ width, height }} />}
      {!imageLoaded && url && <img src={url} width={0} height={0} onLoad={handleOnLoad} onError={handleError} />}
      {imageLoaded && url && <img src={url} width={width} height={height} />}
    </Box>
  );
};

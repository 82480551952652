import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  Divider,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Nullable } from '@src/common-utils/Models';
import { ProductDTO } from '@src/common-utils/DataModels';
import { useEffect, useState } from 'react';
import { ProductDetails } from '@src/components/product/ProductDetails';
import { useUserContext } from '@src/contexts/UserContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@src/layout/RoutePage';
import InfoIcon from '@mui/icons-material/Info';
import { NumberInput } from '@src/components/common/NumberInput';
import { useCartContext } from '@src/contexts/CartContextProvider';
import { ErrorColor, InfoColor } from '@src/components/common/Colors';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const ProductItemSkeleton = ({ product, onLoad }: { product?: Nullable<ProductDTO>; onLoad: () => void }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          // 16:9
          pt: '56.25%',
        }}
      />
      <img style={{ width: 0, height: 0 }} src={product?.images[0].url} onLoad={onLoad} />
      <Skeleton animation="wave" sx={{ flexGrow: 1 }} />
      <CardContent sx={{ flexGrow: 1 }}>
        <Skeleton animation="wave" sx={{ flexGrow: 1 }} />
        <Skeleton animation="wave" sx={{ flexGrow: 1 }} />
        <Skeleton animation="wave" sx={{ flexGrow: 1 }} />
      </CardContent>
      <CardActions>
        <Skeleton animation="wave" sx={{ flexGrow: 1 }} />
      </CardActions>
    </Card>
  );
};

const ProductItem = ({ product }: { product: ProductDTO }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const queryProductCode = new URLSearchParams(location.search).get('productCode');
  // console.log('queryProductCode', queryProductCode);

  const [selected, setSelected] = useState(product.code === queryProductCode);

  const [quantity, setQuantity] = useState(1);

  const { addProductToCart } = useCartContext();

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { userInfo } = useUserContext();

  const [open, setOpen] = useState(false);
  const [readonly, setReadonly] = useState(true);

  const viewDetailsHandler = () => {
    if (isMobile) {
      return;
    }
    setReadonly(userInfo ? false : true);
    setOpen(true);
  };

  const addToCartHandler = () => {
    if (!userInfo) {
      // const redirect = `${ROUTES.home}?productId=${productId}`;
      const redirect = ROUTES.home;
      navigate(`${ROUTES.signin}?redirect=${encodeURIComponent(redirect)}`);
      return;
    }

    addProductToCart(product, quantity);
  };

  useEffect(() => {
    if (userInfo && selected) {
      setOpen(true);
    }
  }, [userInfo, selected]);

  const handleClose = () => {
    setSelected(false);
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardHeader
          // avatar={
          //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
          //     R
          //   </Avatar>
          // }
          // action={
          //   <IconButton aria-label="information" title="Details" size="medium">
          //     <InfoIcon sx={{ color: '#5c87cc' }} fontSize="medium" />
          //   </IconButton>
          // }
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6">{product.code}</Typography>
              {!isMobile && (
                <IconButton aria-label="information" title="Details" size="medium" onClick={viewDetailsHandler}>
                  <InfoIcon sx={{ color: InfoColor }} fontSize="medium" />
                </IconButton>
              )}
            </Box>
          }
          subheader={product.name}
          sx={{ height: '96px' }}
        />
        <CardMedia
          component="img"
          sx={{
            // 16:9
            // pt: '56.25%',
            // pt: '65%',
            height: '180px',
            cursor: 'pointer',
            objectFit: 'contain',
            transition: 'transform .5s', // Smooth transition
            '&:hover': {
              transform: 'scale(1.1)', // Zoom in
            },
          }}
          image={product.images[0].url}
          onClick={viewDetailsHandler}
        />
        <CardContent sx={{ flexGrow: 1, mb: 0, pb: '4px' }}>
          {userInfo && (
            <>
              {product.discount && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <NewReleasesIcon color="error" />
                  </Box>
                  <Typography variant="subtitle1" sx={{ color: ErrorColor, ml: 0.5, mb: 0.5, fontStyle: 'italic' }}>
                    {product.discount.description}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', wordWrap: 'normal' }}>
                <Typography variant="subtitle1">
                  ${product.price}/{product.unit}
                </Typography>
                <Typography variant="subtitle1" sx={{ ml: '2px' }}>
                  {product.packing}
                </Typography>
              </Box>
              <Divider sx={{ mb: 1, mt: 0.5 }} />
            </>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            {product.specification.map((spec, index) => (
              // {specification?.length > 1 && <LensOutlinedIcon fontSize="small"  />}
              <Typography variant="body1" key={`${product.code}-spec-${index}`}>
                {spec}
              </Typography>
            ))}
          </Box>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between', mt: 0, pl: '16px', pr: '16px' }}>
          <NumberInput onChange={setQuantity} initialValue={1} />
          {/* <TextButton onClick={() => addToCartHandler()} showBorder={true}>
            Add to cart
          </TextButton> */}
          <Button variant="outlined" size="small" onClick={addToCartHandler} sx={{ textTransform: 'none' }}>
            Add to cart
          </Button>
        </CardActions>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <ProductDetails product={product!} onClose={() => setOpen(false)} readonly={readonly} />
      </Dialog>
    </>
  );
};

export const ProductCard = ({ product }: { product?: Nullable<ProductDTO> }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <>
      {(!product || !isImageLoaded) && <ProductItemSkeleton product={product} onLoad={() => setIsImageLoaded(true)} />}
      {product && isImageLoaded && <ProductItem product={product!} />}
    </>
  );
};
